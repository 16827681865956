import React, { ChangeEvent } from 'react';
import { Typography } from '@material-ui/core';
import { CreateConsent, UseCaseResponse, CheckboxAccordion } from '@adatree/components';

type DataStepProps = {
  setConsent: (c: CreateConsent) => void;
  consent: CreateConsent;
  useCase: UseCaseResponse;
};

const state = {
  selectedScopes: Array<string>(),
};

export const dataStepButtonValidation = (consent: CreateConsent, useCase: UseCaseResponse): boolean => {
  const mandatoryScopesIds: string[] = new Array<string>();
  if (useCase.scopes) {
    for (const scope of useCase.scopes) {
      if (scope.id) {
        mandatoryScopesIds.push(scope.id);
      }
    }
  }
  return mandatoryScopesIds.every((scope) => state.selectedScopes.includes(scope));
};

const DataStep: React.FC<DataStepProps> = (props) => {
  const { setConsent, consent, useCase } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = event.target;
    if (checked === true) {
      state.selectedScopes.push(value);
    } else {
      state.selectedScopes.splice(state.selectedScopes.indexOf(value), 1);
    }
    consent.useCaseId = useCase.id;
    setConsent({ ...consent } as CreateConsent);
  };

  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        The data we need
      </Typography>
      <Typography className="margin-bt-sm">
        We need to collect the below information in order to provide you with {useCase.name}.
      </Typography>

      {useCase.scopes &&
        useCase.scopes.map((scope, index) => {
          const scopeId = scope.id ? scope.id : '';
          const checked: boolean = state.selectedScopes.includes(scopeId);

          return (
            <CheckboxAccordion
              key={index}
              title={scope.name}
              checkboxValue={scope.id}
              checked={checked}
              items={scope.claims}
              onChange={handleChange}
            />
          );
        })}
    </section>
  );
};

export default DataStep;
