import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { FeedbackMessage } from '@adatree/components';

const _section = styled.section`
  text-align: center;

  ${({ theme }) => `
    padding: ${theme.spacing()};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing()} ${theme.spacing(5)} ${theme.spacing(3)};
    }
  `}
`;

export const ConfirmationTemplate: React.FC = () => {
  return (
    <>
      <_section>
        <FeedbackMessage
          message="Your Consent request was successful"
          icon={<DoneIcon fontSize="large" color="primary" className="margin-bt-xx" />}
          showSpinner={false}
        />
        <Link underline="none" component={RouterLink} to={`/`}>
          Return to Home
        </Link>
      </_section>
    </>
  );
};
