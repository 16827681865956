import React, { useEffect, useState } from 'react';
import InfoSecServiceType from '../../types/infosec-service';
import InfoSecService from '../../services/infosec';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
import { FeedbackMessage, logger } from '@adatree/components';

const _section = styled.section`
  text-align: center;

  ${({ theme }) => `
    padding: ${theme.spacing()};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing()} ${theme.spacing(5)} ${theme.spacing(3)};
    }
  `}
`;

type AuthorizationCallBackPageParams = {
  code: string;
  id_token: string;
  state: string;
};

interface AuthorizationCallBackPageProps extends RouteComponentProps<AuthorizationCallBackPageParams> {
  infoSecService: InfoSecServiceType;
}

const STATE_KEY = 'client_state';

const AuthorizationCallBackPage: React.FC<AuthorizationCallBackPageProps> = (props: AuthorizationCallBackPageProps) => {
  const { hash } = props.location;
  const queryParams = queryString.parse(hash);
  const code: string = queryParams.code as string;
  const idToken: string = queryParams.id_token as string;
  const state: string = queryParams.state as string;
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const localState = sessionStorage.getItem(STATE_KEY);
    const currentUri = window.location.origin + window.location.pathname;
    let localRedirectUri;

    if (localState) localRedirectUri = sessionStorage.getItem(localState);

    if (localState !== state && localRedirectUri !== currentUri) {
      logger.error(
        `CSRF check failed. Local state ${localState} and local URI ${localRedirectUri} do not match callback state ${state} and callback URI ${currentUri}`
      );
      window.location.replace('/');
    }

    if (code && idToken && state) {
      logger.debug('Calling processAuthorization from AuthorizationCallBackPage');
      InfoSecService.processAuthorization(state, code, idToken)
        .then((id: string) => {
          logger.debug('AuthorizationCallBackPage processAuthorization response', id);
          if (localState) sessionStorage.removeItem(localState);
          sessionStorage.removeItem(STATE_KEY);
          setIsProcessing(false);
          window.location.replace(`/consents/${id}`);
        })
        .catch((reason: string) => {
          logger.error(reason);
          window.location.replace('/');
        });
    } else {
      logger.error("It didn't received all the required parameters", queryParams);
      window.location.replace('/');
    }
  }, []);

  return (
    <>
      <_section>{isProcessing && <FeedbackMessage message="Processing your response..." />}</_section>
    </>
  );
};

export default AuthorizationCallBackPage;
