import React, { ReactNode } from 'react';
import { AuthProvider } from 'oidc-react';
import { useHistory } from 'react-router-dom';
import { SETTINGS } from '@adatree/components/src/utils/settings/settings';
import { initAuthentication } from './authentication';

interface Props {
  children: ReactNode;
}

export const AuthenticationProvider: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();
  const authConfig = initAuthentication();
  const showConsentFlow = SETTINGS.application.showConsentFlow;

  const oidcConfig = {
    userManager: authConfig,
    autoSignIn: false,
    onSignIn: async () => {
      if (showConsentFlow) {
        history.push('/new');
      } else {
        history.push('/');
      }
    },
  };

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};
