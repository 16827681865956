import React, { useEffect } from 'react';
import ConsentRepository from '../types/consent-repository';
import { useGlobalState } from '../app/state/app.state';
import { ConsentListOrganism } from '../components/organism/consent-list/consent-list.organism';
import { logger, ConsentResponse } from '@adatree/components';

type ConsentListTemplateProps = {
  consentRepository: ConsentRepository;
};

export const ConsentListTemplate: React.FC<ConsentListTemplateProps> = (props: ConsentListTemplateProps) => {
  const { consentRepository } = props;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [consents, setConsents] = useGlobalState('consents');

  logger.debug('Consect List items', consents);

  useEffect(() => {
    consentRepository.findAll().then((consentResponses: ConsentResponse[]) => {
      setConsents(consentResponses);
      setIsLoading(false);
    });
  }, [consentRepository]);

  return (
    <>
      <ConsentListOrganism isLoading={isLoading} />
    </>
  );
};
