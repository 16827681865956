import React from 'react';
import styled from 'styled-components';
import { CardContent, Typography, Card } from '@material-ui/core';

const _ol = styled.ol`
  ${({ theme }) => `
    margin: ${theme.spacing(4)} 0;
  `}
`;

const HowToExtendSharingDataStep: React.FC = () => {
  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        How to extend your data sharing period
      </Typography>
      <Card>
        <CardContent>
          <Typography>To extrend your data sharing period, we&apos;ll ask you to:</Typography>
          <_ol className="list">
            <li>
              <Typography>
                <strong>Consent</strong> to continue sharing your data with us for the new sharing period
              </Typography>
            </li>
            <li>
              <Typography>
                <strong>Connect</strong> to your bank (we don&apos;t see this information)
              </Typography>
            </li>
            <li>
              <Typography>
                <strong>Confirm</strong> with your bank that you&apos;ll continue sharing data with us
              </Typography>
            </li>
          </_ol>
          <Typography>This should only take a few minutes and we will guide you through each step</Typography>
        </CardContent>
      </Card>
    </section>
  );
};

export default HowToExtendSharingDataStep;
