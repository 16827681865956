import { AxiosResponse } from 'axios';
import ConsentRepository from '../../types/consent-repository';
import {
  SETTINGS,
  ConsentResponse,
  CreateConsent,
  UpdateConsent,
  ConsentApiFactory,
  ConsentConfig,
  ConsentConfigParams,
} from '@adatree/components';
import { getAccessToken } from '../../app/authentication/authentication';

class BackendConsentRepository implements ConsentRepository {
  urlBase: string;
  configuration: ConsentConfig;

  constructor() {
    this.urlBase = `${SETTINGS.backendProtocol}${SETTINGS.backendDomain}/consent-mgmt`;
    const configurationParameters: ConsentConfigParams = {
      basePath: this.urlBase,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    this.configuration = new ConsentConfig(configurationParameters);
  }

  async findAll(): Promise<ConsentResponse[]> {
    this.configuration.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.configuration, this.urlBase).findAllConsents();
    const consents: ConsentResponse[] = response.data;
    return consents;
  }

  async create(consent: CreateConsent): Promise<ConsentResponse> {
    this.configuration.accessToken = await getAccessToken();
    const response: AxiosResponse<ConsentResponse> = await ConsentApiFactory(
      this.configuration,
      this.urlBase
    ).createConsent(consent);
    const consentResponse: ConsentResponse = await response.data;
    return consentResponse;
  }

  async update(consentId: string, consent: UpdateConsent): Promise<ConsentResponse> {
    this.configuration.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.configuration, this.urlBase).updateConsent(consentId, consent);
    if (response.status === 200) {
      return this.findConsentById(consentId);
    }
    return Promise.reject(`Could not update consent. Server responded with: ${response.status}`);
  }

  async revoke(consentId: string): Promise<ConsentResponse> {
    this.configuration.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.configuration, this.urlBase).revokeConsent(consentId);
    if (response.status === 200) {
      return this.findConsentById(consentId);
    }
    return Promise.reject(`Could not update consent. Server responded with: ${response.status}`);
  }

  async findConsentById(consentId: string): Promise<ConsentResponse> {
    this.configuration.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.configuration, this.urlBase).findConsent(consentId);
    const consentResponse: ConsentResponse = await response.data;
    return Promise.resolve(consentResponse);
  }
}

export default BackendConsentRepository;
