import React from 'react';
import { logger, ConsentResponse, UpdateConsent } from '@adatree/components';
import styled from 'styled-components';
import { Typography, Button, Stepper, Step, StepLabel, MobileStepper } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import ConsentRepository from '../../../types/consent-repository';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import HowToExtendSharingDataStep from '../../molecules/consent-flow/extend/steps/how-to-extend-sharing-data-step';
import CdrValuePropositionStep from '../../molecules/consent-flow/common/steps/cdr-value-proposition-step';
import ExistingDataStep from '../../molecules/consent-flow/extend/steps/existing-data-step';
import ExtendPeriodStep from '../../molecules/consent-flow/extend/steps/extend-period-step';
import ReviewStep from '../../molecules/consent-flow/common/steps/review-step';
import { ConsentFormStateEnum } from '../../../pages/new/ConsentFormStateEnum';
import { FeedbackMessage } from '@adatree/components/src/molecules/feedback-message/feedback-message.molecule';
import InfosecService from '../../../types/infosec-service';

const _div = styled.div`
  ${({ theme }) => `
    text-align: right;
    button {
      margin-left: ${theme.spacing(2)}
    }
  `}
`;

type UpdateConsentValidation = () => boolean;

type StepDefinition = {
  title: string;
  content: JSX.Element;
  buttonValidationFunc: UpdateConsentValidation;
};

export type ExtendConsentOrganismProps = {
  consent: ConsentResponse;
  consentRepository: ConsentRepository;
  infosecService: InfosecService;
};

const defaultButtonValidation = () => {
  return true;
};

export const ExtendConsentOrganism: React.FC<ExtendConsentOrganismProps> = (props: ExtendConsentOrganismProps) => {
  const { consent, consentRepository, infosecService } = props;
  const currentSharingEndDate = consent.sharingEndDate;
  const [updatedConsent, setUpdatedConsent] = React.useState<UpdateConsent>({ sharingEndDate: currentSharingEndDate });
  const consentId: string = consent.consentId;
  const history = useHistory();
  const auth = useAuth();
  const [processingContent, setProcessingContent] = React.useState<JSX.Element>();
  const [state, setState] = React.useState(ConsentFormStateEnum.FILLING);

  async function updateConsent(
    setProcessingContent: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>,
    setState: React.Dispatch<React.SetStateAction<ConsentFormStateEnum>>
  ) {
    const messageProcessing = <FeedbackMessage message="Processing your request..." />;
    setProcessingContent(messageProcessing);
    setState(ConsentFormStateEnum.PROCESSING);
    try {
      setProcessingContent(<FeedbackMessage message="Saving your preferences..." />);
      const updatedResponse: ConsentResponse = await consentRepository.update(consentId, updatedConsent);

      setProcessingContent(
        <>
          <FeedbackMessage
            message="Saved"
            showSpinner={false}
            icon={<DoneIcon fontSize="large" color="primary" className="margin-bt-xx" />}
          >
            <Typography>Please wait while we redirect you to {updatedResponse.dataHolderName}</Typography>
          </FeedbackMessage>
        </>
      );
      const dataHolder = updatedResponse.dataHolderBrandId;

      if (dataHolder && updatedResponse.consentId) {
        logger.debug(
          `Calling infosecService.authorization for ${dataHolder} with consent ID ${updatedResponse.consentId}`
        );

        const redirect = await infosecService.authorization(
          dataHolder,
          updatedResponse.consentId,
          updatedResponse.cdrArrangementId
        );

        logger.debug(`InfosecService.authorization returned redirect ${redirect}`);

        window.location.href = redirect;
      } else {
        throw new Error('dataHolder and consentId not defined');
      }
    } catch (err) {
      logger.error('Error creating consent', err);
      setProcessingContent(
        <FeedbackMessage
          message="Sorry we were not able to process your request at the moment. Please try again later. We will now redirect you to the homepage."
          showSpinner={false}
          icon={<ErrorIcon fontSize="large" color="primary" className="margin-bt-xx" />}
        />
      );
      setTimeout(() => {
        window.location.assign('/');
      }, 5000);
    }
  }

  const _Stepper = styled(Stepper)`
    ${({ theme }) => `
  background-color: ${theme.palette.highlight.light}
`}
  `;

  const stepsConfig: StepDefinition[] = [
    {
      title: 'How it works',
      content: (
        <>
          <HowToExtendSharingDataStep />
          <CdrValuePropositionStep />
        </>
      ),
      buttonValidationFunc: defaultButtonValidation,
    },
    {
      title: 'Update sharing expiration date',
      content: (
        <>
          <ExistingDataStep consent={consent} />
          <ExtendPeriodStep
            currentSharingEndDate={currentSharingEndDate}
            update={updatedConsent}
            setUpdatedConsent={setUpdatedConsent}
          />
        </>
      ),
      buttonValidationFunc: defaultButtonValidation,
    },
    {
      title: 'Confirm updated data sharing arrangement',
      content: (
        <ReviewStep
          sharingEndDate={updatedConsent.sharingEndDate}
          postUsageAction={consent.postUsageAction}
          dataHolderName={consent.dataHolderName}
          useCase={consent.useCase}
        />
      ),
      buttonValidationFunc: defaultButtonValidation,
    },
  ];

  const [currentStep, setCurrentStep] = React.useState(0);

  const getStepContent = () => {
    return stepsConfig[currentStep].content;
  };

  const handleNext = async () => {
    if (currentStep === stepsConfig.length - 1) {
      if (auth && auth.userData && auth.userData.profile) {
        consent.consumerEmail = auth.userData.profile.email;
        consent.consumerMobileNumber = auth.userData.profile.phone_number;
      }
      await updateConsent(setProcessingContent, setState);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  0;

  const handleBack = () => {
    if (currentStep === 0) {
      history.push(`/consents/${consentId}/`);
    }
    setCurrentStep(currentStep - 1);
  };

  const wizardButtons = () => {
    if (window.outerWidth < 500) {
      return <></>;
    }
    return (
      <_div>
        <Button onClick={handleBack}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          id="newConsentNextButton"
          disabled={!stepsConfig[currentStep].buttonValidationFunc()}
        >
          {currentStep === 0 ? 'Start' : currentStep === stepsConfig.length - 1 ? 'Confirm' : 'Next'}
        </Button>
      </_div>
    );
  };

  let content = <>{processingContent}</>;

  const calcStepLabel = (step: StepDefinition): string => {
    if (stepsConfig[currentStep] === step) {
      return step.title;
    } else {
      return '';
    }
  };

  if (state === ConsentFormStateEnum.FILLING) {
    let stepperComponent = (
      <_Stepper activeStep={currentStep} className="margin-bt-md">
        {stepsConfig.map((step: StepDefinition, index) => {
          return (
            <Step key={index}>
              <StepLabel>{calcStepLabel(step)}</StepLabel>
            </Step>
          );
        })}
      </_Stepper>
    );
    if (window.outerWidth < 500) {
      stepperComponent = (
        <MobileStepper
          variant="progress"
          steps={stepsConfig.length}
          activeStep={currentStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={!stepsConfig[currentStep].buttonValidationFunc()}>
              Next
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={currentStep === 0}>
              Back
            </Button>
          }
          aria-label={'Progress bar'}
        ></MobileStepper>
      );
    }
    content = (
      <>
        {stepperComponent}
        {getStepContent()}
        {wizardButtons()}
      </>
    );
  }
  return content;
};
