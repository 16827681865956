import UUID from 'uuid';
import InfoSecServiceType from '../../types/infosec-service';
import { logger, SETTINGS } from '@adatree/components';

export default class FakeInfoSecService implements InfoSecServiceType {
  async sleep(milliseconds: number): Promise<string> {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  async processAuthorization(state: string, code: string, idToken: string): Promise<string> {
    logger.debug('Processing FAKE authorization for: ');
    logger.debug('state: ', state);
    logger.debug('code: ', code);
    logger.debug('id_token: ', idToken);
    await this.sleep(1000);
    return Promise.resolve(state);
  }

  authorization(dataHolderBrandId: string, consentId: string, cdrArrangementId?: string): Promise<string> {
    const state = UUID.v4();
    sessionStorage.setItem('client_state', state);
    logger.debug(state, dataHolderBrandId);
    return Promise.resolve(
      `${
        SETTINGS.backendProtocol + SETTINGS.backendDomain
      }/infosec/authorization?dataHolderBrandId=${dataHolderBrandId}&consentId=${consentId}&cdrArrangementId=${cdrArrangementId}`
    );
  }
}
