import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const LogoutPage = (): ReactNode => {
  return (
    <>
      <Typography style={{ textAlign: 'center' }}>
        You have been successfully logged out. Click <Link to="/">here</Link> to log back in.
      </Typography>
    </>
  );
};
