import { Typography } from '@material-ui/core';
import React from 'react';
import { UseCaseResponse, CreateConsent, CheckboxAccordion } from '@adatree/components';

type IntroductionStepProps = {
  consent: CreateConsent;
  useCases: UseCaseResponse[];
  setConsent: (consent: CreateConsent) => void;
};

export const valuePropositionButtonsValidation = (consent: CreateConsent): boolean => {
  return consent.useCaseId !== undefined;
};

const ValuePropositionStep: React.FC<IntroductionStepProps> = (props) => {
  const { consent, useCases, setConsent } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const useCaseId = event.target.value as string;
    const useCase = useCases.find((d) => d.id === useCaseId) as UseCaseResponse;
    consent.useCaseId = useCase.id;
    setConsent({ ...consent } as CreateConsent);
  };

  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        Choose a service
      </Typography>
      <Typography variant="body1" className="margin-bt-sm">
        Which service would you like to use?
      </Typography>

      {useCases.map((useCase, index) => {
        const checked: boolean = consent.useCaseId === useCase.id;

        return (
          <CheckboxAccordion
            key={index}
            title={useCase.name}
            subTitle={useCase.description}
            checkboxValue={useCase.id}
            checked={checked}
            onChange={handleChange}
          />
        );
      })}
    </section>
  );
};

export default ValuePropositionStep;
