import React from 'react';
import styled from 'styled-components';
import { Formatter, Status, ConsentResponse } from '@adatree/components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

const _li = styled.li`
  ${({ theme }) => `
  border-top: 1px solid ${theme.palette.neutral.main};

  &:last-child {
    border-bottom: 1px solid ${theme.palette.neutral.main};
  }

  &:hover {
    background-color: ${theme.palette.neutral.light};
  }
  `}
`;
const _link = styled((props) => <Link {...props} />)`
  ${({ theme }) => `
  padding: ${theme.spacing(2)};
  display: grid;
  grid-template-columns: 3.5rem auto 2rem;
  align-items: center;
  `}
`;

const _div = styled.div`
  ${({ theme }) => `
  padding-left: ${theme.spacing(2)};
  `}
`;

export type ListItemProps = {
  consent: ConsentResponse;
  dataTestId: string;
};

export const ListItem: React.FC<ListItemProps> = (props) => {
  const { consent, dataTestId } = props;
  return (
    <_li data-testid={dataTestId}>
      <_link underline="none" component={RouterLink} to={`/consents/${consent.consentId}`}>
        <AccountBalanceIcon fontSize="large" />
        <_div>
          <Typography variant="h3" display="block" gutterBottom>
            {consent.dataHolderName}
          </Typography>
          <Typography variant="subtitle1" display="block" variantMapping={{ subtitle1: 'h4' }}>
            {consent.status === Status.ACTIVE && <span>Consent granted: {Formatter.formatDate(consent.created)}</span>}
            {consent.status === Status.REQUESTED && (
              <span>Consent requested: {Formatter.formatDate(consent.created)}</span>
            )}
            {consent.status === Status.EXPIRED && (
              <span>Consent expired: {Formatter.formatDate(consent.sharingEndDate)}</span>
            )}
            {consent.status === Status.REVOKED && <span>Consent revoked: {Formatter.formatDate(consent.revoked)}</span>}
          </Typography>
        </_div>
        <ArrowForwardIosIcon fontSize="small" />
      </_link>
    </_li>
  );
};
