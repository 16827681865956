import React, { useEffect } from 'react';
import { ThemeProvider, SETTINGS } from '@adatree/components';
import { CircularProgress } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from '../routes/routes';
import { PageWrapper } from '../../components/organism/page-wrapper/page-wrapper.organism';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { bootstrapApp } from './bootstrap';
import { AuthenticationProvider } from '../authentication/authentication.provider';

// TODO: It must implement accessibility requirements:
//  * https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast.html
//  * https://www.w3.org/TR/UNDERSTANDING-WCAG20/keyboard-operation.html
//  * https://www.w3.org/WAI/WCAG21/Understanding/pointer-accessible
//  * https://www.w3.org/TR/UNDERSTANDING-WCAG20/meaning.html
//  * https://www.w3.org/TR/UNDERSTANDING-WCAG20/minimize-error.html

Bugsnag.start({
  apiKey: SETTINGS.bugsnag.apiKey,
  appType: SETTINGS.bugsnag.appType,
  appVersion: SETTINGS.application.version,
  releaseStage: `${SETTINGS.application.tenant}-${SETTINGS.application.stage}`,
  plugins: [new BugsnagPluginReact()],
});

const App: React.FC = () => {
  bootstrapApp();

  useEffect(() => {
    document.title = `${SETTINGS.companyName}`;
  }, []);

  let content = <CircularProgress />;

  content = (
    <Router>
      <AuthenticationProvider>
        <ThemeProvider>
          <PageWrapper>
            <Routes />
          </PageWrapper>
        </ThemeProvider>
      </AuthenticationProvider>
    </Router>
  );
  return content;
};

export default App;
