import React from 'react';
import { Tooltip, SETTINGS, UpdateConsent } from '@adatree/components';
import styled from 'styled-components';
import { Typography, CardContent, Card } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const _CardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export type ExtendPeriodStepProps = {
  currentSharingEndDate: Date;
  update: UpdateConsent;
  setUpdatedConsent: (updatedConsent: UpdateConsent) => void;
};

const ExtendPeriodStep: React.FC<ExtendPeriodStepProps> = (props: ExtendPeriodStepProps) => {
  const { currentSharingEndDate, update, setUpdatedConsent } = props;
  const { companyName } = SETTINGS;

  const handleDateChange = (date: Date | null) => {
    update.sharingEndDate = date;
    setUpdatedConsent({ ...update } as UpdateConsent);
  };

  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        Expiration date
      </Typography>
      <Tooltip
        title={`How long do you want ${companyName} to be able to access your data?`}
        content={
          <>
            <Typography className="margin-bt-sm">
              This option determines how long {companyName} will be able to request and use your data
            </Typography>
          </>
        }
      />

      <Card className="margin-bt-lg">
        <_CardContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="sharing-end-date-picker"
              label="Expiration Date:"
              disablePast={true}
              format="dd/MM/yyyy"
              data-testid="date-picker"
              value={update.sharingEndDate}
              minDate={currentSharingEndDate}
              maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'Expiration Date',
              }}
            />
          </MuiPickersUtilsProvider>
        </_CardContent>
      </Card>
    </section>
  );
};

export default ExtendPeriodStep;
