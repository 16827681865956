import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { IconWithText } from '@adatree/components';

const HowToShareYourDataStep: React.FC = () => {
  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        How it works
      </Typography>

      <Grid container spacing={3} align="center">
        <Grid item xs>
          <IconWithText
            icon={'CheckboxMultipleMarked'}
            title={'You Consent'}
            text={'to share your information with us'}
          />
        </Grid>
        <Grid item xs>
          <IconWithText icon={'BankCheck'} title={'You Confirm'} text={'with your bank that you want to share it'} />
        </Grid>
        <Grid item xs>
          <IconWithText icon={'Connection'} title={'We Connect'} text={'to your bank securely using Open Banking'} />
        </Grid>
        <Grid item xs>
          <IconWithText icon={'CloudLock'} title={'We Collect'} text={'your information and keep it safe'} />
        </Grid>
      </Grid>
    </section>
  );
};

export default HowToShareYourDataStep;
