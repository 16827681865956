import { getAccessToken } from '../../app/authentication/authentication';
import DataHolderRepository from '../../types/data-holder-repository';
import { SETTINGS, DataHolder, DcrConfig, DcrConfigParams, SupportedDataHoldersApiFactory } from '@adatree/components';

class BackendDataHolderRepository implements DataHolderRepository {
  urlBase: string;
  configuration: DcrConfig;

  constructor() {
    this.urlBase = `${SETTINGS.backendProtocol}${SETTINGS.backendDomain}/dcr`;
    const configurationParameters: DcrConfigParams = {
      basePath: this.urlBase,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    this.configuration = new DcrConfig(configurationParameters);
  }

  async findAll(): Promise<DataHolder[]> {
    this.configuration.accessToken = await getAccessToken();
    const response = await SupportedDataHoldersApiFactory(this.configuration, this.urlBase).getSupportedDataHolders(
      SETTINGS.defaultSoftwareProductId
    );

    return Promise.resolve(response.data.sort((a, b) => a.brandName.localeCompare(b.brandName)));
  }
}

export default BackendDataHolderRepository;
