import React, { ChangeEvent } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography, Card, CardContent } from '@material-ui/core';
import styled from 'styled-components';
import { Tooltip, logger, SETTINGS, CreateConsent, PostUsageAction } from '@adatree/components';

const _CardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type DataRetentionStepProps = {
  consent: CreateConsent;
  setConsent: (consent: CreateConsent) => void;
};

export const retentionNextButtonValidation = (consent: CreateConsent): boolean => {
  return consent.postUsageAction !== undefined;
};

const DataRetentionStep: React.FC<DataRetentionStepProps> = (props) => {
  const { consent } = props;
  const { setConsent } = props;
  const { companyName } = SETTINGS;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    if (target.checked) {
      consent.postUsageAction = event.target.value as PostUsageAction;
      logger.debug('Consent', consent);
      consent.directMarketingAllowed = false;
      setConsent({ ...consent } as CreateConsent);
    }
  };

  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        Data retention
      </Typography>

      <Tooltip
        title={`What should ${companyName} do with your redundant data at consent expiry or withdrawal?`}
        content={
          <>
            <Typography className="margin-bt-sm">
              This option determines what {companyName} will do with your data at consent expiry or withdrawal.
            </Typography>
            <Typography className="margin-bt-sm">
              <strong>Delete</strong>: We will Delete your data at consent expiry or withdrawal.
            </Typography>
            <Typography className="margin-bt-sm">
              <strong>De-identify</strong>: We will De-identify your data at consent expiry or withdrawal.
            </Typography>
          </>
        }
      />

      <Card className="margin-bt-lg">
        <_CardContent>
          <RadioGroup
            aria-label="dataRetention"
            name="dataRetentionPolicy"
            value={consent.postUsageAction ? consent.postUsageAction : ' '}
          >
            <FormControlLabel
              value={PostUsageAction.DELETION}
              control={
                <Radio onChange={handleChange} id={`data-retention-${PostUsageAction.DELETION}`} color="primary" />
              }
              label="Delete"
              labelPlacement="start"
            />
            <FormControlLabel
              value={PostUsageAction.DEIDENTIFICATION}
              control={
                <Radio
                  onChange={handleChange}
                  id={`data-retention-${PostUsageAction.DEIDENTIFICATION}`}
                  color="primary"
                />
              }
              label="De-identify"
              labelPlacement="start"
            />
          </RadioGroup>
        </_CardContent>
      </Card>
    </section>
  );
};

export default DataRetentionStep;
