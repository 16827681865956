import React from 'react';
import styled from 'styled-components';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Formatter, Status } from '@adatree/components';
import { Chip, Typography, Tooltip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { SETTINGS } from '@adatree/components';

const _header = styled.header`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > :nth-child(1) {
      display: none;
    }
    & > :nth-child(2) {
      flex: 1;
    }
    & > :nth-child(3) {
      width: max-content;
      margin-top: ${theme.spacing(2)};
    }

    .data-holder-name {
      display: inline-block;
      margin-right: ${theme.spacing(2)};
      color: ${theme.palette.typography.main};
    }

    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
      align-items: center;

      & > :nth-child(1) {
        display: block;
        width 8rem;
      }

      & > :nth-child(3) {
        margin-top: 0;
      }
    }

  `}
`;

const _Chip = styled(Chip)`
  ${({ theme }) => `
    color: #fff;
    text-transform: lowercase;
    font-size: 1.2rem;
    vertical-align: text-bottom;
    height: 2rem;
    color: ${theme.palette.highlight.light};
    font-weight: 600;

    & span:first-letter {
      text-transform: capitalize;
    }

    &.active {
      background-color: ${theme.palette.success.dark};
    }

    &.revoked {
      background-color: ${theme.palette.error.dark};
    }

    &.requested {
      background-color: ${theme.palette.info.dark};
    }

    &.expired {
      background-color: ${theme.palette.warning.dark};
    }
  `}
`;

export type DataHolderHeaderProps = {
  name?: string;
  status: Status;
  created?: Date;
  revoked?: Date;
  consentId: string;
  onClick: (event: Event) => void;
};

export const DataHolderHeader: React.FC<DataHolderHeaderProps> = (props) => {
  const { name, status, created, revoked, consentId, onClick } = props;

  return (
    <_header>
      <AccountBalanceIcon fontSize="large" />
      <div>
        <Typography variant="h2" data-testid="data-holder-name" className="data-holder-name">
          {name}
        </Typography>
        <_Chip label={status} size="small" className={status.toLowerCase()} />
        <Typography>
          {status === Status.REVOKED
            ? `Revoked at ${Formatter.formatDate(revoked)}`
            : `Consent granted at ${Formatter.formatDate(created)}`}
        </Typography>
      </div>
      {status === Status.ACTIVE && (
        <div>
          {SETTINGS.enableExtendConsent && <Tooltip title="Extend Data Sharing Period">
            <IconButton
              component={RouterLink}
              to={`/consents/${consentId}/extend`}
              id="extendAccessButton"
              data-testid="extend-access-button"
            >
              <DateRangeIcon color="secondary" />
            </IconButton>
          </Tooltip>}
          <Tooltip title="Revoke Data Access">
            <IconButton onClick={onClick}>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </_header>
  );
};
