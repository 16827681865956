import React from 'react';
import { useAuth } from 'oidc-react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { isAuthenticated } from '../authentication/authentication';
import { FeedbackMessage } from '@adatree/components';
import { ConsentDetailTemplateParams } from '../../templates/consent-detail.template';
import { ExtendConsentTemplateParams } from '../../templates/extend-consent.template';

const PrivateRoute: React.FC<{
  component?: React.ComponentType<RouteComponentProps> | React.ComponentType;
  render?: (props: RouteComponentProps | RouteComponentProps<ConsentDetailTemplateParams> | RouteComponentProps<ExtendConsentTemplateParams>) => React.ReactNode;
  path: string;
  exact?: boolean;
}> = (props) => {
  const auth = useAuth();
  const signIn = () => {
    if (auth) auth.signIn();
  };

  if (isAuthenticated()) {
    return <Route {...props} />;
  } else {
    return (
      <>
        <FeedbackMessage message="Please wait while we send you to your authentication provider." />
        {signIn()}
      </>
    );
  }
};
export default PrivateRoute;
