import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { HelpOutline } from '@material-ui/icons';
import { Typography, Tooltip as MuiTooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const _div = styled.div`
  display: flex;
  align-items: center;
`;

const _HelpOutline = styled(HelpOutline)`
  ${({ theme }) => `
    margin-left: ${theme.spacing()};
    color: ${theme.palette.typography.light};
    cursor: pointer;
  `}
`;

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
  },
});

export type TooltipProps = {
  title: string;
  content: ReactNode;
};

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const { title, content } = props;
  const classes = useStyles();

  return (
    <_div>
      <Typography className="margin-bt-sm">{title}</Typography>
      <MuiTooltip title={<>{content}</>} classes={classes}>
        <_HelpOutline fontSize="small" />
      </MuiTooltip>
    </_div>
  );
};
