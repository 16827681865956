import React from 'react';
import styled from 'styled-components';
import { Typography, Card, CardContent } from '@material-ui/core';
import { AccessFrequency, UseCaseResponse, PostUsageAction, Formatter } from '@adatree/components';

const _section = styled.section`
  ${({ theme }) => `
    display: grid;
    grid-gap: ${theme.spacing(2)};
  `}
`;

const _div = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-gap: ${theme.spacing(3)};

    ${theme.breakpoints.up('sm')} {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

export type ReviewStepProps = {
  sharingEndDate: Date;
  dataHolderName: string;
  postUsageAction: PostUsageAction;
  useCase: UseCaseResponse;
};

const showInfo = (label: string, content: string | Date | undefined, dataTestId?: string) => {
  return (
    <>
      <Typography variant="h3">{label}</Typography>
      <Typography data-testid={dataTestId}>{content}</Typography>
    </>
  );
};

const ReviewStep: React.FC<ReviewStepProps> = (props: ReviewStepProps) => {
  const { sharingEndDate, postUsageAction, dataHolderName, useCase } = props;
  const postActionText = postUsageAction === PostUsageAction.DEIDENTIFICATION ? 'De-identify' : 'Delete';
  const frequencyText = useCase.accessFrequency === AccessFrequency.ONGOING ? 'Ongoing' : 'Once-off';
  let sharindEndDate: Date | string = 'After first use';
  if (useCase.accessFrequency === AccessFrequency.ONGOING) {
    sharindEndDate = Formatter.formatDate(sharingEndDate);
  }
  return (
    <_section className="margin-bt-xx">
      <Typography variant="h2">Confirm</Typography>
      <Card className="margin-bt-md">
        <CardContent>
          <_div>
            {showInfo('Your bank:', dataHolderName, 'data-holder-name')}
            {showInfo('Sharing end date:', sharindEndDate)}
            <Typography variant="h3">Types of data:</Typography>
            <div data-testid="data-types">
              {useCase.scopes &&
                useCase.scopes.map((scope) => (
                  <Typography key={scope.id} className="margin-bt-xs">
                    {scope.name}
                  </Typography>
                ))}
            </div>
            {showInfo('Data retention:', postActionText, 'post-action')}
            {showInfo('Purpose of receiving data:', useCase.name, 'purpose')}
            {showInfo('Frequency of access:', frequencyText, 'frequency')}
          </_div>
        </CardContent>
      </Card>
      <Typography variant="body2">
        Consent may be withdrawn at any time for us to receive your data, either through our consent dashboard, from
        {dataHolderName}&apos;s consent dashboard, or in writing to either party. If you withdraw consent, the services
        we offer may cease to provide you with benefits.
      </Typography>
    </_section>
  );
};

export default ReviewStep;
