import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

export type DialogProps = {
  title: string;
  contentText: string;
  open: boolean;
  closeButtonText: string;
  handleClose: () => void;
  actionButtonText?: string;
  handleAction?: () => void;
};

export const Dialog: React.FC<DialogProps> = (props) => {
  const { title, contentText, actionButtonText, closeButtonText, open, handleClose, handleAction } = props;
  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-info"
      aria-describedby="alert-dialog-description-info"
    >
      <DialogTitle id="alert-dialog-title-info">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description-info">{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actionButtonText && handleAction && (
          <Button onClick={handleAction} variant="contained" color="primary" autoFocus>
            {actionButtonText}
          </Button>
        )}
        <Button onClick={handleClose} variant="outlined" color="secondary">
          {closeButtonText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
