import UUID from 'uuid';
import InfoSecService from '../../types/infosec-service';
import { getAccessToken } from '../../app/authentication/authentication';
import {
  logger,
  SETTINGS,
  InfosecConfig,
  InfosecConfigParams,
  Authorization,
  AuthorizationApiFactory,
  TokensApiFactory,
} from '@adatree/components';

const STATE_KEY = 'client_state';
class BackendInfoSecService implements InfoSecService {
  urlBase: string;

  constructor() {
    this.urlBase = `${SETTINGS.backendProtocol}${SETTINGS.backendDomain}/infosec`;
  }

  async processAuthorization(state: string, code: string, idToken: string): Promise<string> {
    const parametersConfiguration: InfosecConfigParams = {
      accessToken: await getAccessToken(),
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    const configuration: InfosecConfig = new InfosecConfig(parametersConfiguration);
    const authorization: Authorization = {
      code,
      state,
      id_token: idToken,
    };

    logger.debug('Calling ExternalTokensApiFactory from BackendInfoSecService.processAuthorization');

    const response = await TokensApiFactory(configuration, this.urlBase).createToken(authorization);

    logger.debug('TokensApiFactory response', response, response.data);

    if (response.status === 201) {
      sessionStorage.removeItem(STATE_KEY);

      // @ts-ignore
      return Promise.resolve(response.data.activeConsentId);
    }
    logger.error('About to fail the create credential flow because the response was not 201', response);
    return Promise.reject('Could not process the request');
  }

  async authorization(dataHolderBrandId: string, consentId: string, cdrArrangementId?: string): Promise<string> {
    const state = UUID.v4();
    // Store the state and redirect Uri for future security check
    sessionStorage.setItem(STATE_KEY, state);
    sessionStorage.setItem(state, SETTINGS.redirectUri);

    const parametersConfiguration: InfosecConfigParams = {
      accessToken: await getAccessToken(),
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    const configuration: InfosecConfig = new InfosecConfig(parametersConfiguration);
    const response = await AuthorizationApiFactory(configuration, this.urlBase).getAuthorization(
      dataHolderBrandId,
      undefined,
      state,
      undefined,
      consentId,
      cdrArrangementId,
      undefined,
      undefined,
      SETTINGS.redirectUri
    );

    const redirect = response.data;

    logger.debug('AuthorizationApiFactory redirect ', redirect);

    if (response.status === 200) {
      return Promise.resolve(redirect);
    }
    return Promise.reject('Could not process the request');
  }
}

export default BackendInfoSecService;
