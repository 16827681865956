import React, { ChangeEvent, useEffect } from 'react';
import { Tooltip, SETTINGS, CreateConsent, AccessFrequency, UseCaseResponse } from '@adatree/components';
import styled from 'styled-components';
import { Typography, FormControlLabel, Radio, RadioGroup, CardContent, Card } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const _CardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type PeriodStepProps = {
  consent: CreateConsent;
  useCase: UseCaseResponse;
  setConsent: (c: CreateConsent) => void;
};

export const periodStepNextButtonValidation = (consent: CreateConsent, useCase: UseCaseResponse): boolean => {
  return (
    useCase.accessFrequency === AccessFrequency.ONCEOFF ||
    (useCase.accessFrequency === AccessFrequency.ONGOING && consent.sharingEndDate !== null)
  );
};

const PeriodStep: React.FC<PeriodStepProps> = (props: PeriodStepProps) => {
  const { consent, useCase, setConsent } = props;
  const { companyName } = SETTINGS;

  useEffect(() => {
    if (!consent.sharingEndDate) {
      consent.sharingEndDate = new Date();
      setConsent({ ...consent } as CreateConsent);
    }
  }, []);

  const handleDateChange = (date: Date | null) => {
    consent.sharingEndDate = date;
    setConsent({ ...consent } as CreateConsent);
  };

  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    if (target.checked) {
      useCase.accessFrequency = event.target.value as AccessFrequency;
      if (useCase.accessFrequency === AccessFrequency.ONGOING) {
        consent.sharingEndDate = new Date();
      }
      setConsent({ ...consent } as CreateConsent);
    }
  };

  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        Expiration date
      </Typography>
      <Tooltip
        title={`How long do you want ${companyName} to be able to access your data?`}
        content={
          <>
            <Typography className="margin-bt-sm">
              This option determines how long {companyName} will be able to request and use your data
            </Typography>
            <Typography className="margin-bt-sm">
              <strong>Once-off</strong>: {companyName} will be able to request and use your data only once. After the
              initial load of your data {companyName} will not be able to request fresh data from your bank.
            </Typography>
            <Typography className="margin-bt-sm">
              <strong>Ongoing</strong>: {companyName} will be able to request and use your data up to a period of 12
              months. During this period we may access your data multiple times per day.
            </Typography>
          </>
        }
      />

      <Card className="margin-bt-lg">
        <_CardContent>
          <RadioGroup
            aria-label="access-cardinality-group"
            name="accessCardinalityGroup"
            value={useCase.accessFrequency ? useCase.accessFrequency : ' '}
          >
            <FormControlLabel
              value={AccessFrequency.ONCEOFF}
              disabled={useCase.accessFrequency !== AccessFrequency.ONCEOFF}
              control={<Radio id="access-cardinality-single" color="primary" />}
              label="Once-off"
              labelPlacement="start"
              onChange={handleChangeRadio}
            />
            <FormControlLabel
              value={AccessFrequency.ONGOING}
              disabled={useCase.accessFrequency !== AccessFrequency.ONGOING}
              control={<Radio id="access-frequency-ongoing" color="primary" />}
              label="Ongoing"
              labelPlacement="start"
              onChange={handleChangeRadio}
            />
          </RadioGroup>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              disabled={useCase.accessFrequency !== AccessFrequency.ONGOING}
              id="sharing-end-date-picker"
              label="Expiration Date:"
              disablePast={true}
              format="dd/MM/yyyy"
              data-testid="date-picker"
              value={consent.sharingEndDate}
              maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'Expiration Date',
              }}
            />
          </MuiPickersUtilsProvider>
        </_CardContent>
      </Card>
    </section>
  );
};

export default PeriodStep;
