import ConsentRepository from '../../types/consent-repository';
import { TestUtil, ConsentResponse, CreateConsent, UpdateConsent, Status } from '@adatree/components';

function resolveWithFakeNetworkLatency(response: ConsentResponse): Promise<ConsentResponse> {
  const NETWORK_LATENCY = Number(process.env.REACT_APP_FAKE_NETWORK_LATENCY) || 0;
  return new Promise((resolve) => setTimeout(() => resolve(response), NETWORK_LATENCY));
}

function resolveArrayWithFakeNetworkLatency(response: ConsentResponse[]): Promise<ConsentResponse[]> {
  const NETWORK_LATENCY = Number(process.env.REACT_APP_FAKE_NETWORK_LATENCY) || 0;
  return new Promise((resolve) => setTimeout(() => resolve(response), NETWORK_LATENCY));
}

class FakeConsentRepository implements ConsentRepository {
  private readonly consents = TestUtil.getTestDataConsentResponses();

  async findAll(): Promise<ConsentResponse[]> {
    return resolveArrayWithFakeNetworkLatency(this.consents);
  }

  create(createConsent: CreateConsent): Promise<ConsentResponse> {
    const consent = TestUtil.generateTestDataConsent(createConsent);
    return resolveWithFakeNetworkLatency(consent);
  }

  update(consentId: string, updateConsent: UpdateConsent): Promise<ConsentResponse> {
    const consent = this.consents.find((i) => i.consentId === consentId);

    if (updateConsent.directMarketingAllowed !== undefined) {
      consent.directMarketingAllowed = updateConsent.directMarketingAllowed;
    }
    if (updateConsent.postUsageAction) {
      consent.postUsageAction = updateConsent.postUsageAction;
    }
    if (updateConsent.sharingEndDate) {
      consent.sharingEndDate = updateConsent.sharingEndDate;
    }

    return resolveWithFakeNetworkLatency(consent);
  }

  revoke(consentId: string): Promise<ConsentResponse> {
    return this.findConsentById(consentId).then((revokedConsent) => {
      revokedConsent.status = Status.REVOKED;
      revokedConsent.revoked = new Date();
      return resolveWithFakeNetworkLatency(revokedConsent);
    });
  }

  findConsentById(consentId: string): Promise<ConsentResponse> {
    const newLocal = this.consents.find((i) => i.consentId === consentId);
    if (newLocal !== undefined) {
      return resolveWithFakeNetworkLatency(newLocal);
    }
    return resolveWithFakeNetworkLatency({});
  }
}

export default FakeConsentRepository;
