import { Button, MobileStepper, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useEffect } from 'react';
import ConsentRepository from '../../types/consent-repository';
import UseCaseRepository from '../../types/use-case-repository';
import DataHolderRepository from '../../types/data-holder-repository';
import InfosecService from '../../types/infosec-service';
import { ConsentFormStateEnum } from './ConsentFormStateEnum';
import CdrValuePropositionStep from './steps/cdr-value-proposition-step';
import DataRetentionStep, { retentionNextButtonValidation } from './steps/data-retention-step';
import DataStep, { dataStepButtonValidation } from './steps/data-step';
import DataholderStep, { dataHolderWizardButtonsValidation } from './steps/dataholder/dataholder-step';
import PeriodStep, { periodStepNextButtonValidation } from './steps/period-step';
import ReviewStep from './steps/review-step';
import ValuePropositionStep, { valuePropositionButtonsValidation } from './steps/value-proposition-step';
import HowToShareYourDataStep from './steps/how-to-share-your-data-step';
import { useAuth } from 'oidc-react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  FeedbackMessage,
  logger,
  CreateConsent,
  ConsentResponse,
  UseCaseResponse,
  DataHolder,
} from '@adatree/components';
import DoneIcon from '@material-ui/icons/Done';

const _divContent = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(5)};
    }
  `}
`;

const _div = styled.div`
  ${({ theme }) => `
    text-align: right;
    button {
      margin-left: ${theme.spacing(2)}
    }
  `}
`;

const _Stepper = styled(Stepper)`
  ${({ theme }) => `
    background-color: ${theme.palette.background.paper}
  `}
`;

interface ConsentFormProps {
  consentRepository: ConsentRepository;
  infosecService: InfosecService;
  useCaseRepository: UseCaseRepository;
  dataHolderRepository: DataHolderRepository;
}

type CreateConsentValidation = (consent: CreateConsent) => boolean;
type CreateConsentAndUseCaseResponseValidation = (consent: CreateConsent, useCase: UseCaseResponse) => boolean;

const defaultButtonValidation = () => {
  return true;
};

const getSelectedUseCase = (c: CreateConsent, useCases: UseCaseResponse[]) => {
  return useCases.find((useCase) => useCase.id === c.useCaseId) as UseCaseResponse;
};

const consentStepButtonValidation = (consent: CreateConsent, useCase: UseCaseResponse): boolean => {
  return (
    periodStepNextButtonValidation(consent, useCase) &&
    dataStepButtonValidation(consent, useCase) &&
    retentionNextButtonValidation(consent)
  );
};

type StepDefinition = {
  title: string;
  content: JSX.Element;
  buttonValidationFunc: CreateConsentValidation | CreateConsentAndUseCaseResponseValidation;
};

async function createConsent(
  setProcessingContent: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>,
  setState: React.Dispatch<React.SetStateAction<ConsentFormStateEnum>>,
  props: React.PropsWithChildren<ConsentFormProps>,
  consent: CreateConsent
) {
  const messageProcessing = <FeedbackMessage message="Processing your request..." />;
  setProcessingContent(messageProcessing);
  setState(ConsentFormStateEnum.PROCESSING);
  const { consentRepository, infosecService } = props;
  try {
    setProcessingContent(<FeedbackMessage message="Saving your preferences..." />);
    const createdConsent: ConsentResponse = await consentRepository.create(consent);

    setProcessingContent(
      <>
        <FeedbackMessage
          message="Saved"
          showSpinner={false}
          icon={<DoneIcon fontSize="large" color="primary" className="margin-bt-xx" />}
        >
          <Typography>Please wait while we redirect you to {consent.dataHolderName}</Typography>
        </FeedbackMessage>
      </>
    );
    const dataHolder = createdConsent.dataHolderBrandId;

    if (dataHolder && createdConsent.consentId) {
      logger.debug(
        `Calling infosecService.authorization for ${dataHolder} with consent ID ${createdConsent.consentId}`
      );

      const redirect = await infosecService.authorization(dataHolder, createdConsent.consentId);

      logger.debug(`InfosecService.authorization returned redirect ${redirect}`);

      window.location.href = redirect;
    } else {
      throw new Error('dataHolder and consentId not defined');
    }
  } catch (err) {
    logger.error('Error creating consent', err);
    setProcessingContent(
      <FeedbackMessage
        message="Sorry we were not able to process your request at the moment. Please try again later. We will now redirect you to the homepage."
        showSpinner={false}
        icon={<ErrorIcon fontSize="large" color="primary" className="margin-bt-xx" />}
      />
    );
    setTimeout(() => {
      window.location.assign('/');
    }, 5000);
  }
}

const ConsentForm: React.FC<ConsentFormProps> = (props) => {
  const { useCaseRepository, dataHolderRepository, consentRepository } = props;
  const [consent, setConsent] = React.useState<CreateConsent>({});
  const [useCases, setUseCases] = React.useState<UseCaseResponse[]>([]);
  const [dataHolders, setDataHolders] = React.useState<DataHolder[]>([]);
  const [existingConsents, setExistingConsents] = React.useState<ConsentResponse[]>([]);
  const [state, setState] = React.useState(ConsentFormStateEnum.FILLING);
  const [processingContent, setProcessingContent] = React.useState<JSX.Element>();
  const history = useHistory();
  const auth = useAuth();
  const useCase = getSelectedUseCase(consent, useCases);

  useEffect(() => {
    useCaseRepository.findAll().then((foundUseCases: UseCaseResponse[]) => {
      setUseCases(foundUseCases);
    });
  }, [useCaseRepository]);

  useEffect(() => {
    dataHolderRepository.findAll().then((foundDataHolders: DataHolder[]) => {
      setDataHolders(foundDataHolders);
    });
  }, [dataHolderRepository]);

  useEffect(() => {
    consentRepository.findAll().then((foundConsents: ConsentResponse[]) => {
      setExistingConsents(foundConsents);
    });
  }, [consentRepository]);

  const stepsConfig: StepDefinition[] = [
    {
      title: 'CDR',
      content: (
        <>
          <CdrValuePropositionStep />
        </>
      ),
      buttonValidationFunc: defaultButtonValidation,
    },
    {
      title: 'How it works',
      content: (
        <>
          <HowToShareYourDataStep />
        </>
      ),
      buttonValidationFunc: defaultButtonValidation,
    },
    {
      title: 'Choose',
      content: <ValuePropositionStep consent={consent} useCases={useCases} setConsent={setConsent} />,
      buttonValidationFunc: valuePropositionButtonsValidation,
    },
    {
      title: 'Data',
      content: (
        <>
          <DataStep consent={consent} useCase={useCase} setConsent={setConsent} />
          <PeriodStep consent={consent} useCase={useCase} setConsent={setConsent} />
          <DataRetentionStep consent={consent} setConsent={setConsent} />
        </>
      ),
      buttonValidationFunc: consentStepButtonValidation,
    },
    {
      title: 'Connect',
      content: (
        <DataholderStep
          dataHolders={dataHolders}
          consent={consent}
          existingConsents={existingConsents}
          setConsent={setConsent}
        />
      ),
      buttonValidationFunc: dataHolderWizardButtonsValidation,
    },
    {
      title: 'Summary',
      content: <ReviewStep consent={consent} useCase={useCase} />,
      buttonValidationFunc: defaultButtonValidation,
    },
  ];

  const [currentStep, setCurrentStep] = React.useState(0);

  const getStepContent = () => {
    return stepsConfig[currentStep].content;
  };

  const handleNext = async () => {
    if (currentStep === stepsConfig.length - 1) {
      if (auth && auth.userData && auth.userData.profile) {
        if (auth.userData.profile.email != undefined) {
          consent.consumerEmail = auth.userData.profile.email;
        } else if (auth.userData.profile.emails != undefined) {
          consent.consumerEmail = auth.userData.profile.emails[0];
        }
        consent.consumerMobileNumber = auth.userData.profile.phone_number;
      }
      await createConsent(setProcessingContent, setState, props, consent);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep === 0) {
      history.push('/');
    }
    setCurrentStep(currentStep - 1);
  };

  const wizardButtons = () => {
    if (window.outerWidth < 500) {
      return <></>;
    }
    return (
      <_div>
        <Button onClick={handleBack}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          id="newConsentNextButton"
          disabled={!stepsConfig[currentStep].buttonValidationFunc(consent, useCase)}
        >
          {currentStep === 0 ? 'Start' : currentStep === stepsConfig.length - 1 ? 'Consent' : 'Next'}
        </Button>
      </_div>
    );
  };
  let content = <>{processingContent}</>;

  const calcStepLabel = (step: StepDefinition): string => {
    if (stepsConfig[currentStep] === step) {
      return step.title;
    } else {
      return '';
    }
  };
  if (state === ConsentFormStateEnum.FILLING) {
    let stepperComponent = (
      <_Stepper activeStep={currentStep} className="margin-bt-md">
        {stepsConfig.map((step: StepDefinition, index) => {
          return (
            <Step key={index}>
              <StepLabel>{calcStepLabel(step)}</StepLabel>
            </Step>
          );
        })}
      </_Stepper>
    );
    // TODO Remove use of window object
    if (window.outerWidth < 500) {
      stepperComponent = (
        <MobileStepper
          variant="progress"
          steps={stepsConfig.length}
          activeStep={currentStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={!stepsConfig[currentStep].buttonValidationFunc(consent, useCase)}
            >
              {currentStep === 0 ? 'Start' : currentStep === stepsConfig.length - 1 ? 'Consent' : 'Next'}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={currentStep === 0}>
              Back
            </Button>
          }
          aria-label={'Progress bar'}
        ></MobileStepper>
      );
    }
    content = (
      <_divContent>
        {stepperComponent}
        {getStepContent()}
        {wizardButtons()}
      </_divContent>
    );
  }
  return content;
};

export default ConsentForm;
