import React from 'react';
import { Typography, Avatar, makeStyles, Theme } from '@material-ui/core';
import CheckboxMultipleMarked from 'mdi-material-ui/CheckboxMultipleMarked';
import BankCheck from 'mdi-material-ui/BankCheck';
import Connection from 'mdi-material-ui/Connection';
import CloudLock from 'mdi-material-ui/CloudLock';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '80px',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export type IconWithTextProps = {
  icon: 'CheckboxMultipleMarked' | 'BankCheck' | 'Connection' | 'CloudLock';
  title: string;
  text: string;
};

export const IconWithText: React.FC<IconWithTextProps> = (props) => {
  const { icon, title, text } = props;
  const classes = useStyles();
  let altText = 'Avatar icon';

  const getIcon = (icon: string) => {
    switch (icon) {
      case 'CheckboxMultipleMarked':
        altText = 'Multiple checkboxes marked';
        return <CheckboxMultipleMarked className={classes.icon} />;
      case 'BankCheck':
        altText = 'Bank checked';
        return <BankCheck className={classes.icon} />;
      case 'Connection':
        altText = 'Plug connection';
        return <Connection className={classes.icon} />;
      case 'CloudLock':
        altText = 'Secure cloud';
        return <CloudLock className={classes.icon} />;
    }
  };

  return (
    <div className={classes.wrapper}>
      <Avatar className={classes.avatar} alt={altText}>
        {getIcon(icon)}
      </Avatar>
      <Typography variant="h2">{title}</Typography>
      <Typography>{text}</Typography>
    </div>
  );
};
