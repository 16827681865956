import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { CircularProgress, Typography } from '@material-ui/core';

const _div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => `
  padding: ${theme.spacing(2)};
  `}
`;

export type FeedbackMessageProps = {
  message: string;
  showSpinner?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
};

export const FeedbackMessage: React.FC<FeedbackMessageProps> = ({
  showSpinner = true,
  ...props
}: FeedbackMessageProps) => {
  const { message, icon, children } = props;
  return (
    <_div>
      {showSpinner && <CircularProgress className="margin-bt-xx" aria-label="Loading" />}
      {icon}
      <Typography>{message}</Typography>
      {children}
    </_div>
  );
};
