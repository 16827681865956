import React, { useState } from 'react';
import { Typography, AppBar, Tabs, Tab, Box, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { ConsentResponse, Status, ListItem } from '@adatree/components';
import styled from 'styled-components';

const _divLoading = styled.div`
  ${({ theme }) => `
    padding: 0 ${theme.spacing(3)};
  `}
`;

const _div = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: flex-end;
    padding-right: ${theme.spacing(3)};
  `}
`;

export type ConsentTabsProps = {
  consents: ConsentResponse[];
  isLoading: boolean;
  showConsentFlow?: boolean;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const ConsentTabs: React.FC<ConsentTabsProps> = ({ showConsentFlow = true, ...props }: ConsentTabsProps) => {
  const { consents, isLoading } = props;
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const filterItems = (consentItems: ConsentResponse[], filter: Status): JSX.Element => {
    const filteredArray = consentItems.filter((consent: ConsentResponse) => consent.status === filter);

    if (filteredArray.length === 0) {
      return (
        <>
          <Typography variant="h5" align="center" key="empty" className="margin-bt-xl">
            You do not have any {filter.toLowerCase()} consents.
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <ul>
            {filteredArray
              .sort((a, b) => {
                let sortDateA;
                let sortDateB;

                if (filter === Status.ACTIVE || filter === Status.REQUESTED) {
                  sortDateA = a.created;
                  sortDateB = b.created;
                } else if (filter === Status.EXPIRED) {
                  sortDateA = a.sharingEndDate;
                  sortDateB = b.sharingEndDate;
                } else if (filter === Status.REVOKED) {
                  sortDateA = a.revoked;
                  sortDateB = b.revoked;
                }

                if (sortDateA && sortDateB) {
                  return sortDateA < sortDateB ? 1 : -1;
                }
                return 0;
              })
              .map((consent: ConsentResponse, index: number) => {
                const key = `current-consent-row-${index}`;
                return <ListItem consent={consent} key={key} dataTestId={key} />;
              })}
          </ul>
        </>
      );
    }
  };

  return (
    <section>
      <AppBar position="static" className="margin-bt-xx">
        <Tabs value={tabIndex} onChange={handleChange} variant="fullWidth" aria-label="Consents by status">
          <Tab label="Current" />
          <Tab label="Expired" />
          <Tab label="Revoked" />
        </Tabs>
      </AppBar>

      {showConsentFlow && (
        <_div className="margin-bt-md">
          <Button component={RouterLink} to="/new" id="connectYouBankButton" variant="contained" color="primary">
            New Consent
          </Button>
        </_div>
      )}

      {isLoading && (
        <_divLoading>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </_divLoading>
      )}
      {!isLoading && (
        <div className="margin-bt-md">
          <TabPanel value={tabIndex} index={0}>
            {filterItems(consents, Status.ACTIVE)}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            {filterItems(consents, Status.EXPIRED)}
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            {filterItems(consents, Status.REVOKED)}
          </TabPanel>
        </div>
      )}
    </section>
  );
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-label={`Consent tab`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};
