import React from 'react';
import { Link } from 'react-router-dom';
import { LogoutButton } from '../../atoms/logout/logout-button.atom';
import styled from 'styled-components';

const _header = styled.header`
  position: relative;
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(10)} ${theme.spacing(2)} ${theme.spacing(4)};

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(8)} ${theme.spacing(2)};
    }

    & > a > img {
      max-width: 30rem
    }
  `}
`;

const _div = styled.div`
  position: absolute;
  ${({ theme }) => `
    top: ${theme.spacing(1)} ;
    right: ${theme.spacing(2)} ;

    ${theme.breakpoints.up('sm')} {
      top: ${theme.spacing(2)} ;
    }
  `}
`;

export type AppHeaderProps = {
  companyLogoUrl: string;
  showLogout?: boolean;
  handleLogout: () => void;
};

export const AppHeader: React.FC<AppHeaderProps> = (props) => {
  const { companyLogoUrl, handleLogout, showLogout } = props;

  return (
    <_header>
      <Link to="/">
        <img src={companyLogoUrl} alt="Company Logo" />
      </Link>
      {showLogout && (
        <_div>
          <LogoutButton handleClick={handleLogout} />
        </_div>
      )}
    </_header>
  );
};
