import React from 'react';
import { Footer, SETTINGS } from '@adatree/components';
import { useGlobalState } from '../../../app/state/app.state';
import { ConsentTabs } from '../../molecules/consent-tabs/consent-tabs.molecule';

type ConsentListOrganismProps = {
  isLoading: boolean;
};

export const ConsentListOrganism: React.FC<ConsentListOrganismProps> = (props: ConsentListOrganismProps) => {
  const { isLoading } = props;
  const [consents] = useGlobalState('consents');
  const showConsentFlow = SETTINGS.application.showConsentFlow;

  return (
    <>
      <ConsentTabs consents={consents} isLoading={isLoading} showConsentFlow={showConsentFlow} />
      <Footer />
    </>
  );
};
