import BackendInfoSecService from './backend-infosec-service';
import InfoSecServiceType from '../../types/infosec-service';
import FakeInfoSecService from './fake-infosec-service';
import { logger, SETTINGS } from '@adatree/components';

let Service: InfoSecServiceType = new BackendInfoSecService();
if (SETTINGS.simulateBackend) {
  logger.info('========== Simulating infosec calls ==========');
  Service = new FakeInfoSecService();
}

export default Service;
