import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  Avatar,
  TextField,
} from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CreateConsent, ConsentResponse, Status, DataHolder } from '@adatree/components';
import { Link } from 'react-router-dom';

const _section = styled.section`
  ${({ theme }) => `
    display: grid;
    grid-gap: ${theme.spacing(2)}
  `}
`;

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      height: 'auto',
      maxHeight: 25,
      minWidth: 25
    },
    option: {
      '& > span': {
        marginRight: 10
      }
    }
  })
);

export type DataholderStepProps = {
  consent: CreateConsent;
  setConsent: (consent: CreateConsent) => void;
  dataHolders: DataHolder[];
  existingConsents: ConsentResponse[];
};

export const dataHolderWizardButtonsValidation = (consent: CreateConsent): boolean => {
  return consent.dataHolderBrandId !== undefined;
};

export const isExistingConsent = (
  currentConsent: CreateConsent,
  existingConsents: ConsentResponse[],
  dataHolder: DataHolder
): ConsentResponse[] => {
  const tempArray = existingConsents.filter((existing) => {
    if (existing.useCase) {
      return (
        existing.status === Status.ACTIVE &&
        existing.useCase.id === currentConsent.useCaseId &&
        existing.dataHolderBrandId === dataHolder.dataHolderBrandId
      );
    }
    return false;
  });
  return tempArray;
};

const DataholderStep: React.FC<DataholderStepProps> = (props) => {
  const { consent, setConsent, dataHolders, existingConsents } = props;

  let availableDataHolders = dataHolders;
  let existingUseCaseConsents: ConsentResponse[] = [];

  if (existingConsents) {
    availableDataHolders = dataHolders.filter((dataHolder) => {
      const tempArray = isExistingConsent(consent, existingConsents, dataHolder);
      existingUseCaseConsents = [...existingUseCaseConsents, ...tempArray];
      return tempArray.length === 0;
    });
  }

  const handleChange = (event: React.ChangeEvent, value: DataHolder | null) => {
    if (value) {
      consent.dataHolderName = value.brandName;
      consent.dataHolderBrandId = value.dataHolderBrandId;
    } else {
      consent.dataHolderName = undefined;
      consent.dataHolderBrandId = undefined;
    }
    setConsent({ ...consent } as CreateConsent);
  };

  const classes = useStyles();

  return (
    <_section className="margin-bt-xx">
      <Typography variant="h2">Connect your bank</Typography>

      {/* <Card>
        <CardContent> */}
          {availableDataHolders.length > 0 && (
            <Autocomplete
              id="dataHolderSelect"
              fullWidth
              onChange={handleChange}
              options={availableDataHolders}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.brandName}
              renderOption={(option) => (
                <React.Fragment>
                  <span>
                    <Avatar alt={option.brandName} src={option.logoUri} className={classes.avatar} variant="square" >
                      <AccountBalanceIcon/>
                    </Avatar>
                  </span>
                  <Typography variant="h3">{option.brandName}</Typography>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose your bank"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}
          {availableDataHolders.length === 0 && existingUseCaseConsents && existingUseCaseConsents.length > 0 && (
            <>
              {existingUseCaseConsents.map((sameConsent) => (
                <>
                  {sameConsent.useCase && (
                    <Typography className="margin-bt-md" key={sameConsent.useCase.name}>
                      You already have an active
                      <Link to={`/consents/${sameConsent.consentId}`}> {sameConsent.useCase.name} </Link>
                      consent for {sameConsent.dataHolderName}.
                    </Typography>
                  )}
                </>
              ))}
            </>
          )}
      <Typography variant="h3">Why is my bank not listed?</Typography>
      <Typography>
        Every bank in Australia must eventually join the Consumer Data Right but not every bank has their systems ready to connect. New banks are joining all the time. If your bank is not listed you have the right to enquire with them as to when they will be ready.
      </Typography>
    </_section>
  );
};

export default DataholderStep;
