import React from 'react';
import {
  Avatar,
  Typography,
  SvgIcon,
  makeStyles,
  Theme
} from '@material-ui/core';
import styled from 'styled-components';

const _div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const _SvgIcon = styled(SvgIcon)`
  font-size: 10rem;

`;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  }
}));

export type AvatarHeadingProps = {
  icon: typeof SvgIcon;
  title: string;
  contentText: string;
};

export const AvatarHeading: React.FC<AvatarHeadingProps> = (props) => {
  const { icon, title, contentText } = props;
  const classes = useStyles();
  return (
    <_div>
      <Avatar className={classes.avatar} alt={title}>
        <_SvgIcon>{icon}</_SvgIcon>
      </Avatar>
      <Typography variant="h2">{title}</Typography>
      <Typography>{contentText}</Typography>
    </_div>
  );
};
