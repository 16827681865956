import { logger, SETTINGS } from '@adatree/components';
import { User, UserManager } from 'oidc-react';
import { getState, setState, StateType } from '../state/state';
import { getGlobalState, setGlobalState } from '../state/app.state';

export const initAuthentication = (): UserManager => {
  const userManager = new UserManager({
    authority: SETTINGS.oidc.authority,
    client_id: SETTINGS.oidc.clientId,
    redirect_uri: SETTINGS.oidc.redirectUri,
    response_type: SETTINGS.oidc.responseType,
    scope: SETTINGS.oidc.scope,
    loadUserInfo: true,
    extraQueryParams: JSON.parse(SETTINGS.oidc.extraQueryParams),
    loadUserInfo: false,
    metadata: {
      issuer: SETTINGS.oidc.issuer,
      jwks_uri: SETTINGS.oidc.jwksUri,
      token_endpoint: SETTINGS.oidc.tokenEndpoint,
      userinfo_endpoint: SETTINGS.oidc.userinfoEndpoint,
      authorization_endpoint: SETTINGS.oidc.authority,
      end_session_endpoint: SETTINGS.oidc.endSessionEndpoint,
    },
  });

  setState(StateType.Authentication, userManager);

  return userManager;
};

export const isAuthenticated = (): boolean => {
  const app = getGlobalState('app');
  if (!SETTINGS.enableAuthentication) {
    setGlobalState('app', { ...app, isAuthenticated: true });
    return true;
  }
  let auth = false;
  const item = sessionStorage.getItem(`oidc.user:${SETTINGS.oidc.authority}:${SETTINGS.oidc.clientId}`);

  if (item) {
    try {
      auth = isAuthValid(item);
    } catch (error) {
      logger.error('Error parsing authentication data from session storage', error);
    }
  }

  setGlobalState('app', { ...app, isAuthenticated: auth });
  return auth;
};

export const getAccessToken = async (): Promise<string> => {
  return getState()
    .authentication.getUser()
    .then((user: User | null) => {
      return user ? user.access_token : '';
    });
};

const isAuthValid = (item: string): boolean => {
  const data = JSON.parse(item);
  return data && data.access_token && data.expires_at && isValidExpiresAt(data.expires_at);
};

const isValidExpiresAt = (secondsSinceEpochTime: number): boolean => {
  const nowInSeconds = Math.floor(Date.now() / 1000);
  return nowInSeconds < secondsSinceEpochTime ? true : false;
};
