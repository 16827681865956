import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Link,
  makeStyles,
  Checkbox,
  Typography,
} from '@material-ui/core';

const _div = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
  `}
`;


const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0,
  },
});

export type CheckboxAccordionProps = {
  title?: string;
  subTitle?: string;
  checkboxValue?: string;
  checked?: boolean;
  items?: string[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxAccordion: React.FC<CheckboxAccordionProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { title, subTitle, checkboxValue, checked, items, onChange } = props;
  const accordionOpenText = 'Hide';
  const accordionClosedText = `See what's included`;

  const toggleAccordion = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Card className="margin-bt-lg">
      <CardContent>
        <FormGroup>
          <FormControlLabel
            label={<Typography variant="h3">{title}</Typography>}
            labelPlacement="start"
            control={
              <Checkbox
                value={checkboxValue}
                id={`chk-${checkboxValue}`}
                onChange={onChange}
                color="primary"
                checked={checked}
              />
            }
            classes={classes}
          />
        </FormGroup>

        <Typography variant="h4">{subTitle}</Typography>

        {items && items.length > 0 && (
          <>
            <Link href="#" onClick={toggleAccordion}>
              {isOpen ? accordionOpenText : accordionClosedText}
            </Link>
            {isOpen && (
              <_div>
                <ul className="list">
                  {items.map((item: string, i: number) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </_div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
