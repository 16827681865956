import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AuthenticationPage } from '../../pages/auth/authentication.page';
import AuthorizationCallBackPage from '../../pages/callback';
import ConsentForm from '../../pages/new/consent-form';
import ConsentRepository from '../../repositories/consent-repository';
import UseCaseRepository from '../../repositories/use-case-repository';
import DataHolderRepository from '../../repositories/data-holder-repository';
import InfosecService from '../../services/infosec';
import { SETTINGS } from '@adatree/components';
import PrivateRoute from './private-route';
import { ConsentListTemplate } from '../../templates/consent-list.template';
import { ConsentDetailTemplate, ConsentDetailTemplateParams } from '../../templates/consent-detail.template';
import { ExtendConsentTemplate, ExtendConsentTemplateParams } from '../../templates/extend-consent.template';
import { ConfirmationTemplate } from '../../templates/confirmation/confirmation.template';
import { LogoutPage } from '../../pages/auth/logout.page';

export const Routes: React.FC = () => {
  const showConsentFlow = SETTINGS.application.showConsentFlow;

  return (
    <Switch>
      <Route path="/authentication" component={AuthenticationPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/redirect" component={AuthorizationCallBackPage} />
      {showConsentFlow && (
        <PrivateRoute
          path="/new"
          render={(props: RouteComponentProps) => {
            return (
              <ConsentForm
                {...props}
                consentRepository={ConsentRepository}
                infosecService={InfosecService}
                useCaseRepository={UseCaseRepository}
                dataHolderRepository={DataHolderRepository}
              />
            );
          }}
        />
      )}
      {showConsentFlow && (
        <PrivateRoute
          exact
          path="/consents/:consentId/extend"
          render={(props: RouteComponentProps<ExtendConsentTemplateParams>) => {
            return <ExtendConsentTemplate {...props} consentRepository={ConsentRepository} infosecService={InfosecService} />;
          }}
        />
      )}
      <PrivateRoute
        exact
        path="/consents/:consentId"
        render={(props: RouteComponentProps<ConsentDetailTemplateParams>) => {
          return <ConsentDetailTemplate {...props} consentRepository={ConsentRepository} />;
        }}
      />
      <PrivateRoute path="/confirmation" component={ConfirmationTemplate} />
      <PrivateRoute
        exact
        path="/"
        render={() => {
          return <ConsentListTemplate consentRepository={ConsentRepository} />;
        }}
      />
    </Switch>
  );
};
