import React from 'react';
import styled from 'styled-components';
import { Typography, Link } from '@material-ui/core';
import { SETTINGS } from '@adatree/components';

const _div = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-gap: ${theme.spacing(0)} ${theme.spacing(4)};
    grid-template-columns: 13rem auto;
    grid-template-areas:
      "logo ."
      "logo .";

    & > :first-child {
      grid-area: logo;
    }

    margin ${theme.spacing(4)} 0;
  `}
`;

const CdrValuePropositionStep: React.FC = () => {
  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        Share data with the Consumer Data Right
      </Typography>
      {/* <Card> */}
        {/* <CardContent> */}
          <div>
          <Typography>
            You can connect us with your bank via the{' '}
            <Link href="https://www.cdr.gov.au/">
              Consumer Data Right
            </Link>
            . It is quick, safe, accurate and Government regulated.
          </Typography>
          </div>
          <_div>
            <img src="images/consumer-data-right.png" style={{ width: '100%' }} alt="Consumer data right logo" />
            <Typography variant="body2" color="secondary">
              {SETTINGS.companyName}
            </Typography>
            <Typography variant="body2" color="secondary">
              Accredited Data Recipient: {SETTINGS.accreditationNumber}
            </Typography>
          </_div>
          <Typography className="margin-bt-md">
            We are an Accredited Consumer Data Right Recipient, meaning we are bound by rules set by the Australian
            Government on how we can handle your data.
          </Typography>
          <Typography className="margin-bt-md">
            Learn more about this on{' '}
            <Link href="https://www.cdr.gov.au/">
              cdr.gov.au
            </Link>
          </Typography>
        {/* </CardContent> */}
      {/* </Card> */}
    </section>
  );
};

export default CdrValuePropositionStep;
