import React from 'react';
import styled from 'styled-components';
import { Typography, Link, CardContent, Card } from '@material-ui/core';
import { SETTINGS } from '@adatree/components';

const _div = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-gap: ${theme.spacing(0)} ${theme.spacing(4)};
    grid-template-columns: 13rem auto;
    grid-template-areas:
      "logo ."
      "logo .";

    & > :first-child {
      grid-area: logo;
    }

    margin ${theme.spacing(4)} 0;
  `}
`;

const CdrValuePropositionStep: React.FC = () => {
  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        Know your rights
      </Typography>
      <Card>
        <CardContent>
          <div>
            <Typography variant="h4" className="margin-bt-md" variantMapping={{ h4: 'h3' }}>
              Share data with the Consumer Data Right
            </Typography>
            <Typography>
              You can connect us with your bank via the Consumer Data Right. This option is quick, accurate and can save
              you time by providing us with the correct information.{' '}
            </Typography>
          </div>
          <_div>
            <img src="../../images/consumer-data-right.png" style={{ width: '100%' }} alt="Consumer data right logo" />
            <Typography variant="body2" color="secondary">
              {SETTINGS.companyName}
            </Typography>
            <Typography variant="body2" color="secondary">
              Accredited Data Recipient: {SETTINGS.accreditationNumber}
            </Typography>
          </_div>
          <Typography className="margin-bt-md">
            We are an Accredited Consumer Data Right Recipient, meaning we are bound by rules set by the Australian
            Government on how we can handle your data. Learn more about this on{' '}
            <Link href="https://www.accc.gov.au">www.accc.gov.au</Link>
          </Typography>
          <div>
            <Typography variant="h4" className="margin-bt-sm" variantMapping={{ h4: 'h3' }}>
              Key things you should know:
            </Typography>
            <ul className="list">
              <li>
                <Typography variant="body2">We do not share or use your data without your consent</Typography>
              </li>
              <li>
                <Typography variant="body2">You can easily stop sharing your bank data with us at anytime</Typography>
              </li>
              <li>
                <Typography variant="body2">You can choose to delete shared data that we no longer need</Typography>
              </li>
            </ul>
          </div>
        </CardContent>
      </Card>
    </section>
  );
};

export default CdrValuePropositionStep;
