// tslint:disable
/**
 * Consent Dashboard Consent API Overview
 * Consent Dashboard Consent REST API for ADRs. This allows consumers to create records of consent granted to access their consumer data.
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessFrequency {
    ONCEOFF = 'ONCE_OFF',
    ONGOING = 'ONGOING'
}

/**
 * 
 * @export
 * @interface ConsentHistory
 */
export interface ConsentHistory {
    /**
     * 
     * @type {Array<ConsentResponse>}
     * @memberof ConsentHistory
     */
    consents?: Array<ConsentResponse>;
}
/**
 * 
 * @export
 * @interface ConsentResponse
 */
export interface ConsentResponse {
    /**
     * 
     * @type {number}
     * @memberof ConsentResponse
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    revoked?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    lastNotificationSentAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    firstDataCollection?: string;
    /**
     * 
     * @type {Status}
     * @memberof ConsentResponse
     */
    status?: Status;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    sharingEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consumerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    dataHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    dataHolderBrandId?: string;
    /**
     * 
     * @type {UseCaseResponse}
     * @memberof ConsentResponse
     */
    useCase?: UseCaseResponse;
    /**
     * 
     * @type {PostUsageAction}
     * @memberof ConsentResponse
     */
    postUsageAction?: PostUsageAction;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consumerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    cdrArrangementId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConsentResponse
     */
    directMarketingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consumerMobileNumber?: string;
}
/**
 * 
 * @export
 * @interface CreateConsent
 */
export interface CreateConsent {
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    consumerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    sharingEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    dataHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    dataHolderBrandId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    useCaseId?: string;
    /**
     * 
     * @type {PostUsageAction}
     * @memberof CreateConsent
     */
    postUsageAction?: PostUsageAction;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConsent
     */
    directMarketingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    consumerMobileNumber?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    EMAIL = 'EMAIL',
    SMS = 'SMS'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PostUsageAction {
    DELETION = 'DELETION',
    DEIDENTIFICATION = 'DE_IDENTIFICATION'
}

/**
 * 
 * @export
 * @interface ResponseErrorListErrors
 */
export interface ResponseErrorListErrors {
    /**
     * Error code
     * @type {string}
     * @memberof ResponseErrorListErrors
     */
    code: string;
    /**
     * Title of invalid parameter or payload property
     * @type {string}
     * @memberof ResponseErrorListErrors
     */
    title: string;
    /**
     * detailed error message
     * @type {string}
     * @memberof ResponseErrorListErrors
     */
    detail: string;
    /**
     * Optional additional data for specific error types
     * @type {object}
     * @memberof ResponseErrorListErrors
     */
    meta?: object;
}
/**
 * 
 * @export
 * @interface RevokeConsent
 */
export interface RevokeConsent {
    /**
     * 
     * @type {string}
     * @memberof RevokeConsent
     */
    revokingParty?: RevokeConsentRevokingPartyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RevokeConsentRevokingPartyEnum {
    HOLDER = 'DATA_HOLDER',
    RECIPIENT = 'DATA_RECIPIENT'
}

/**
 * 
 * @export
 * @interface Scope
 */
export interface Scope {
    /**
     * 
     * @type {string}
     * @memberof Scope
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Scope
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Scope
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Scope
     */
    claims?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Scope
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Scope
     */
    required?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Scope
     */
    priority?: number;
}
/**
 * 
 * @export
 * @interface ScopeResponse
 */
export interface ScopeResponse {
    /**
     * 
     * @type {string}
     * @memberof ScopeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ScopeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ScopeResponse
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScopeResponse
     */
    claims?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ScopeResponse
     */
    required?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScopeResponse
     */
    priority?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Status {
    REQUESTED = 'REQUESTED',
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    REVOKED = 'REVOKED'
}

/**
 * 
 * @export
 * @interface UpdateConsent
 */
export interface UpdateConsent {
    /**
     * 
     * @type {PostUsageAction}
     * @memberof UpdateConsent
     */
    postUsageAction?: PostUsageAction;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConsent
     */
    directMarketingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConsent
     */
    sharingEndDate?: string;
}
/**
 * 
 * @export
 * @interface UseCaseResponse
 */
export interface UseCaseResponse {
    /**
     * 
     * @type {string}
     * @memberof UseCaseResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseResponse
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UseCaseResponse
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseResponse
     */
    historicalCollectionPeriodInDays?: number;
    /**
     * 
     * @type {NotificationType}
     * @memberof UseCaseResponse
     */
    notificationType?: NotificationType;
    /**
     * 
     * @type {Array<ScopeResponse>}
     * @memberof UseCaseResponse
     */
    scopes?: Array<ScopeResponse>;
    /**
     * 
     * @type {AccessFrequency}
     * @memberof UseCaseResponse
     */
    accessFrequency?: AccessFrequency;
}

/**
 * ConsentApi - axios parameter creator
 * @export
 */
export const ConsentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a consent record for consumer
         * @summary Create Consent
         * @param {CreateConsent} [createConsent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsent: async (createConsent?: CreateConsent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createConsent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createConsent !== undefined ? createConsent : {}) : (createConsent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all consent records for this consumer
         * @summary Get Consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllConsents: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retreive a single consent record for this consumer
         * @summary Get Consent
         * @param {string} consentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConsent: async (consentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consentId' is not null or undefined
            if (consentId === null || consentId === undefined) {
                throw new RequiredError('consentId','Required parameter consentId was null or undefined when calling findConsent.');
            }
            const localVarPath = `/consents/{consentId}`
                .replace(`{${"consentId"}}`, encodeURIComponent(String(consentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke a single consent record for this consumer
         * @summary Revoke Consent
         * @param {string} consentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeConsent: async (consentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consentId' is not null or undefined
            if (consentId === null || consentId === undefined) {
                throw new RequiredError('consentId','Required parameter consentId was null or undefined when calling revokeConsent.');
            }
            const localVarPath = `/consents/{consentId}`
                .replace(`{${"consentId"}}`, encodeURIComponent(String(consentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a single consent record for this consumer
         * @summary Update Consent
         * @param {string} consentId 
         * @param {UpdateConsent} [updateConsent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsent: async (consentId: string, updateConsent?: UpdateConsent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consentId' is not null or undefined
            if (consentId === null || consentId === undefined) {
                throw new RequiredError('consentId','Required parameter consentId was null or undefined when calling updateConsent.');
            }
            const localVarPath = `/consents/{consentId}`
                .replace(`{${"consentId"}}`, encodeURIComponent(String(consentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateConsent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateConsent !== undefined ? updateConsent : {}) : (updateConsent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsentApi - functional programming interface
 * @export
 */
export const ConsentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create a consent record for consumer
         * @summary Create Consent
         * @param {CreateConsent} [createConsent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsent(createConsent?: CreateConsent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentResponse>> {
            const localVarAxiosArgs = await ConsentApiAxiosParamCreator(configuration).createConsent(createConsent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all consent records for this consumer
         * @summary Get Consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllConsents(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsentResponse>>> {
            const localVarAxiosArgs = await ConsentApiAxiosParamCreator(configuration).findAllConsents(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retreive a single consent record for this consumer
         * @summary Get Consent
         * @param {string} consentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findConsent(consentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentResponse>> {
            const localVarAxiosArgs = await ConsentApiAxiosParamCreator(configuration).findConsent(consentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Revoke a single consent record for this consumer
         * @summary Revoke Consent
         * @param {string} consentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeConsent(consentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConsentApiAxiosParamCreator(configuration).revokeConsent(consentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a single consent record for this consumer
         * @summary Update Consent
         * @param {string} consentId 
         * @param {UpdateConsent} [updateConsent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsent(consentId: string, updateConsent?: UpdateConsent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConsentApiAxiosParamCreator(configuration).updateConsent(consentId, updateConsent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConsentApi - factory interface
 * @export
 */
export const ConsentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Create a consent record for consumer
         * @summary Create Consent
         * @param {CreateConsent} [createConsent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsent(createConsent?: CreateConsent, options?: any): AxiosPromise<ConsentResponse> {
            return ConsentApiFp(configuration).createConsent(createConsent, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all consent records for this consumer
         * @summary Get Consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllConsents(options?: any): AxiosPromise<Array<ConsentResponse>> {
            return ConsentApiFp(configuration).findAllConsents(options).then((request) => request(axios, basePath));
        },
        /**
         * Retreive a single consent record for this consumer
         * @summary Get Consent
         * @param {string} consentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConsent(consentId: string, options?: any): AxiosPromise<ConsentResponse> {
            return ConsentApiFp(configuration).findConsent(consentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke a single consent record for this consumer
         * @summary Revoke Consent
         * @param {string} consentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeConsent(consentId: string, options?: any): AxiosPromise<void> {
            return ConsentApiFp(configuration).revokeConsent(consentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a single consent record for this consumer
         * @summary Update Consent
         * @param {string} consentId 
         * @param {UpdateConsent} [updateConsent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsent(consentId: string, updateConsent?: UpdateConsent, options?: any): AxiosPromise<void> {
            return ConsentApiFp(configuration).updateConsent(consentId, updateConsent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsentApi - object-oriented interface
 * @export
 * @class ConsentApi
 * @extends {BaseAPI}
 */
export class ConsentApi extends BaseAPI {
    /**
     * Create a consent record for consumer
     * @summary Create Consent
     * @param {CreateConsent} [createConsent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public createConsent(createConsent?: CreateConsent, options?: any) {
        return ConsentApiFp(this.configuration).createConsent(createConsent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all consent records for this consumer
     * @summary Get Consents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public findAllConsents(options?: any) {
        return ConsentApiFp(this.configuration).findAllConsents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retreive a single consent record for this consumer
     * @summary Get Consent
     * @param {string} consentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public findConsent(consentId: string, options?: any) {
        return ConsentApiFp(this.configuration).findConsent(consentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Revoke a single consent record for this consumer
     * @summary Revoke Consent
     * @param {string} consentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public revokeConsent(consentId: string, options?: any) {
        return ConsentApiFp(this.configuration).revokeConsent(consentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a single consent record for this consumer
     * @summary Update Consent
     * @param {string} consentId 
     * @param {UpdateConsent} [updateConsent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public updateConsent(consentId: string, updateConsent?: UpdateConsent, options?: any) {
        return ConsentApiFp(this.configuration).updateConsent(consentId, updateConsent, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ScopeApi - axios parameter creator
 * @export
 */
export const ScopeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllScopes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/scopes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScopeApi - functional programming interface
 * @export
 */
export const ScopeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllScopes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScopeResponse>>> {
            const localVarAxiosArgs = await ScopeApiAxiosParamCreator(configuration).findAllScopes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ScopeApi - factory interface
 * @export
 */
export const ScopeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllScopes(options?: any): AxiosPromise<Array<ScopeResponse>> {
            return ScopeApiFp(configuration).findAllScopes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScopeApi - object-oriented interface
 * @export
 * @class ScopeApi
 * @extends {BaseAPI}
 */
export class ScopeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeApi
     */
    public findAllScopes(options?: any) {
        return ScopeApiFp(this.configuration).findAllScopes(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UseCaseApi - axios parameter creator
 * @export
 */
export const UseCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all use-cases that have been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
         * @summary Get Use-cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUseCases: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/use-cases`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UseCaseApi - functional programming interface
 * @export
 */
export const UseCaseApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all use-cases that have been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
         * @summary Get Use-cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllUseCases(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCaseResponse>>> {
            const localVarAxiosArgs = await UseCaseApiAxiosParamCreator(configuration).findAllUseCases(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UseCaseApi - factory interface
 * @export
 */
export const UseCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get all use-cases that have been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
         * @summary Get Use-cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUseCases(options?: any): AxiosPromise<Array<UseCaseResponse>> {
            return UseCaseApiFp(configuration).findAllUseCases(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UseCaseApi - object-oriented interface
 * @export
 * @class UseCaseApi
 * @extends {BaseAPI}
 */
export class UseCaseApi extends BaseAPI {
    /**
     * Get all use-cases that have been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
     * @summary Get Use-cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCaseApi
     */
    public findAllUseCases(options?: any) {
        return UseCaseApiFp(this.configuration).findAllUseCases(options).then((request) => request(this.axios, this.basePath));
    }

}


