import { logger } from '../logger/logger';

const DEFAULT_REQUIRED = 'NOT_SET';
const DEFAULT_OPTIONAL = '';
const isDebug = localStorage.getItem('debug');

// Application
const application = {
  tenant: process.env.REACT_APP_TENANT || DEFAULT_REQUIRED,
  stage: process.env.REACT_APP_STAGE || DEFAULT_REQUIRED,
  theme: process.env.REACT_APP_THEME || DEFAULT_REQUIRED,
  timestamp: process.env.REACT_APP_TIMESTAMP || DEFAULT_OPTIONAL,
  version: process.env.REACT_APP_VERSION || 'no-version-set',
  showConsentFlow: process.env.REACT_APP_SHOW_CONSENT_FLOW !== 'false', // Default is true
};
const backendProtocol = process.env.REACT_APP_BACKEND_PROTOCOL || DEFAULT_REQUIRED;
const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN || DEFAULT_REQUIRED;
const enableAuthentication = process.env.REACT_APP_ENABLE_AUTHENTICATION === 'true';
const enableDebug = process.env.REACT_APP_ENABLE_DEBUG === 'true' || isDebug === 'true';
const simulateBackend = process.env.REACT_APP_SIMULATE_BACKEND === 'true';
const enableExtendConsent = process.env.REACT_APP_ENABLE_EXTEND_CONSENT === 'true';

// Company
const accreditationNumber = process.env.REACT_APP_ACCREDITATION_NUMBER || DEFAULT_REQUIRED;
const companyName = process.env.REACT_APP_COMPANY_NAME || DEFAULT_REQUIRED;
const companyLogo = process.env.REACT_APP_COMPANY_LOGO || DEFAULT_REQUIRED;
const companyWebSite = process.env.REACT_APP_COMPANY_WEBSITE || DEFAULT_REQUIRED;
const companyCdrPolicyUrl = process.env.REACT_APP_COMPANY_CDR_POLICY_URL || 'https://www.adatree.com.au/cdrpolicy';
const defaultSoftwareProductId = process.env.REACT_APP_DEFAULT_SOFTWARE_PRODUCT_ID || DEFAULT_REQUIRED;
const redirectUri = process.env.REACT_APP_REDIRECT_URI || DEFAULT_REQUIRED;

// OIDC
const oidc = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY_URI || DEFAULT_REQUIRED,
  clientId: process.env.REACT_APP_OIDC_CLIENT_ID || DEFAULT_REQUIRED,
  redirectUri: process.env.REACT_APP_OIDC_REDIRECT_URI || DEFAULT_REQUIRED,
  responseType: process.env.REACT_APP_OIDC_RESPONSE_TYPE || DEFAULT_REQUIRED,
  scope: process.env.REACT_APP_OIDC_SCOPE || DEFAULT_REQUIRED,
  extraQueryParams: process.env.REACT_APP_OIDC_EXTRA_QUERY_PARAMS || DEFAULT_REQUIRED, // JSON string example '{"audience":"https://some-domain.com/api/v2/"}'
  issuer: process.env.REACT_APP_OIDC_ISSUER || DEFAULT_REQUIRED,
  jwksUri: process.env.REACT_APP_OIDC_JWKS_URI || DEFAULT_REQUIRED,
  tokenEndpoint: process.env.REACT_APP_OIDC_TOKEN_ENDPOINT || DEFAULT_REQUIRED,
  userinfoEndpoint: process.env.REACT_APP_OIDC_USERINFO_ENDPOINT || DEFAULT_REQUIRED,
  endSessionEndpoint: process.env.REACT_APP_OIDC_END_SESSION_ENDPOINT || DEFAULT_REQUIRED,
};

// Bugsnag
const bugsnag = {
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || DEFAULT_REQUIRED,
  appType: process.env.REACT_APP_BUGSNAG_TYPE || DEFAULT_REQUIRED,
};

type Application = {
  tenant: string;
  stage: string;
  theme: string;
  timestamp: string;
  version: string;
  showConsentFlow: boolean;
};

type Oidc = {
  authority: string;
  clientId: string;
  redirectUri: string;
  responseType: string;
  scope: string;
  extraQueryParams: string;
  issuer: string;
  jwksUri: string;
  tokenEndpoint: string;
  userinfoEndpoint: string;
  endSessionEndpoint: string;
};
type Bugsnag = {
  apiKey: string;
  appType: string;
};

export type Settings = {
  application: Application;
  accreditationNumber: string;
  companyName: string;
  companyLogo: string;
  companyWebSite: string;
  backendProtocol: string;
  backendDomain: string;
  simulateBackend: boolean;
  enableAuthentication: boolean;
  enableDebug: boolean;
  defaultSoftwareProductId: string;
  redirectUri: string;
  oidc: Oidc;
  bugsnag: Bugsnag;
  enableExtendConsent: boolean;
  companyCdrPolicyUrl: string;
};

export const SETTINGS: Settings = {
  application,
  accreditationNumber,
  companyName,
  companyLogo,
  companyWebSite,
  backendProtocol,
  backendDomain,
  simulateBackend,
  enableAuthentication,
  enableDebug,
  defaultSoftwareProductId,
  redirectUri,
  oidc,
  bugsnag,
  enableExtendConsent,
  companyCdrPolicyUrl,
};

const checkSettings = (): void => {
  try {
    if (
      isNotSet(SETTINGS, 'Root') ||
      isNotSet(SETTINGS.application, 'Application') ||
      isNotSet(SETTINGS.oidc, 'OIDC') ||
      isNotSet(SETTINGS.bugsnag, 'Bugsnag')
    ) {
      throw new Error('Settings error');
    }
  } catch (error) {
    logger.error('Missing Settings configuration - Application may not work correctly');
  }
};

const isNotSet = (settingObject: unknown, objectName: string): boolean => {
  let invalidSettings = false;

  for (const [key, value] of Object.entries(settingObject)) {
    if (typeof value === 'string' && value === DEFAULT_REQUIRED) {
      invalidSettings = true;
      logger.error(`Setting ${objectName} - ${key} is not set`);
    }
  }

  return invalidSettings;
};

checkSettings();
