import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ConsentRepository from '../types/consent-repository';
import { ConsentResponse } from '@adatree/components';
import Skeleton from '@material-ui/lab/Skeleton';
import { ConsentDetailOrganism } from '../components/organism/consent-detail/consent-detail.organism';
import styled from 'styled-components';

const _div = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(3)} 0;
    padding: ${theme.spacing()};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(5)};
    }
  `}
`;

export type ConsentDetailTemplateParams = {
  consentId: string;
};
interface ConsentDetailTemplateProps extends RouteComponentProps<ConsentDetailTemplateParams> {
  consentRepository: ConsentRepository;
}

export const ConsentDetailTemplate: React.FC<ConsentDetailTemplateProps> = (props: ConsentDetailTemplateProps) => {
  const { consentRepository } = props;
  const consentId: string = props.match.params.consentId;
  const [consent, setConsent] = React.useState<ConsentResponse>();
  const [isloading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    consentRepository.findConsentById(consentId).then((foundConsent: ConsentResponse) => {
      setConsent(Object.assign({}, foundConsent));
      setIsLoading(false);
    });
  }, [consentId, consentRepository]);

  return (
    <>
      <_div>
        {isloading && (
          <>
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
          </>
        )}
        {!isloading && consent && <ConsentDetailOrganism consent={consent} consentRepository={consentRepository} />}
      </_div>
    </>
  );
};
