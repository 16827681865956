import { createGlobalState } from 'react-hooks-global-state';
import { AppState, State } from '../types/app.types';
import { getDefaultConsentState } from './consent.state';

export const bootstrapState = (): void => {
  setGlobalState('app', getDefaultAppState());
  setGlobalState('consents', getDefaultConsentState());
};

export const getDefaultState = (): State => {
  return {
    app: getDefaultAppState(),
    consents: getDefaultConsentState(),
  };
};

export const getState = (): State => {
  return {
    app: getGlobalState('app'),
    consents: getGlobalState('consents'),
  };
};

export const getDefaultAppState = (): AppState => {
  return {
    isAuthenticated: false,
  };
};

const { useGlobalState, getGlobalState, setGlobalState } = createGlobalState(getDefaultState());

export { useGlobalState, getGlobalState, setGlobalState };
