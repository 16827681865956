import FakeConsentRepository from './fake-consent-repository';
import BackendConsentRepository from './backend-consent-repository';
import ConsentRepository from '../../types/consent-repository';
import { logger, SETTINGS } from '@adatree/components';

let repository: ConsentRepository = new BackendConsentRepository();
if (SETTINGS.simulateBackend) {
  logger.info('========== Simulating consents calls ==========');
  repository = new FakeConsentRepository();
}

export default repository;
