import '../themes/global.css';
import React, { ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { theme as applicationTheme } from '../themes/theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { CssBaseline, StylesProvider } from '@material-ui/core';

interface Props {
  children: ReactNode;
}

export const ThemeProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={applicationTheme}>
        <StyledThemeProvider theme={applicationTheme}>
          <CssBaseline />
          {children}
        </StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
