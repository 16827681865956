import { logger } from '../../utils/logger/logger';
import adatree from '../tenants/adatree.theme.json';
import liberty from '../tenants/liberty.theme.json';
import payble from '../tenants/payble.theme.json';
import payOk from '../tenants/payok.theme.json';
import covidHotspotAlert from '../tenants/covid-hotspot-alert.theme.json';

export type ApplicationTheme = {
  colour: {
    primary: string;
    secondary: string;
    highlight: {
      light: string;
      main: string;
      dark: string;
    };
    shadow: {
      light: string;
      main: string;
      dark: string;
    };
    neutral: {
      light: string;
      main: string;
      dark: string;
    };
    typography: {
      light: string;
      main: string;
      dark: string;
    };
    active: {
      main: string;
    };
    success: string;
    background: {
      header: string;
      paper: string;
      main: string;
    };
  };
  font: {
    fontFamily: string;
    baseHtmlFontSize: number;
  };
  spacing: {
    scalingFactor: number;
  };
};

const getTheme = (theme: string): ApplicationTheme => {
  switch (theme) {
    case 'adatree.theme.json':
      return adatree;
      break;
    case 'covid-hotspot-alert.theme.json':
      return covidHotspotAlert;
      break;
    case 'liberty.theme.json':
      return liberty;
      break;
    case 'payble.theme.json':
      return payble;
      break;
    case 'payok.theme.json':
      return payOk;
      break;
    default:
      logger.error('Theme not set. Using default');
      return adatree;
      break;
  }
};

export const themeSwitcher = {
  getTheme,
};
