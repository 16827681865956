import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import styled from 'styled-components';

const _MuiAccordion = styled(MuiAccordion)`
  .MuiAccordionSummary-root {
    display: flex;
    padding: 0rem 1.6rem;
  }
`;

export type AccordionProps = {
  title: string;
  content: JSX.Element;
};

export const Accordion: React.FC<AccordionProps> = (props) => {
  const { title, content } = props;

  return (
    <_MuiAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-content-${title.replace(' ', '-')}`}
        id={`panel-id-${title.replace(' ', '-')}`}
      >
        <Typography variant="h3">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{content} </AccordionDetails>
    </_MuiAccordion>
  );
};
