import FakeUseCaseRepository from './fake-use-case-repository';
import BackendUseCaseRepository from './backend-use-case-repository';
import UseCaseRepository from '../../types/use-case-repository';
import { logger, SETTINGS } from '@adatree/components';

let repository: UseCaseRepository = new BackendUseCaseRepository();
if (SETTINGS.simulateBackend) {
  logger.info('========== Simulating use-cases calls ==========');
  repository = new FakeUseCaseRepository();
}

export default repository;
