import { UseCaseResponse, AccessFrequency } from '@adatree/components';
import UseCaseRepository from '../../types/use-case-repository';

class FakeUseCaseRepository implements UseCaseRepository {
  async findAll(): Promise<UseCaseResponse[]> {
    return [
      {
        id: 'HOME_LOAN',
        name: 'Home Loan Assessment',
        description: 'Data will be used to assess your eligibility for a home loan.',
        priority: 1,
        accessFrequency: AccessFrequency.ONCEOFF,
        scopes: [
          {
            name: 'Personal information',
            id: 'common:customer.basic:read',
            description: 'This will allow us to access your personally identifiable information',
            claims: ['Name', 'Occupation'],
            required: true,
            priority: 1,
          },
          {
            name: 'Bank account name, type and balance',
            id: 'bank:accounts.basic:read',
            description: 'This will allow us to access basic information about your accounts',
            claims: ['Name of account', 'Type of account', 'Account balance'],
            required: true,
            priority: 2,
          },
        ],
      },
      {
        id: 'BUDGETING_TOOL',
        name: 'Budgeting Tool',
        description: 'Data will be used to help you with your budget.',
        priority: 2,
        accessFrequency: AccessFrequency.ONGOING,
        scopes: [
          {
            name: 'Bank account name, type and balance',
            id: 'bank:accounts.basic:read',
            description: 'This will allow us to access basic information about your accounts',
            claims: ['Name of account', 'Type of account', 'Account balance'],
            required: true,
            priority: 2,
          },
          {
            name: 'Bank account numbers and features',
            id: 'bank:accounts.detail:read',
            description: 'This will allow us to access detailed information about your accounts',
            claims: ['Account number', 'Interest rates', 'Fees', 'Discounts', 'Account terms', 'Account mail address'],
            required: true,
            priority: 3,
          },
          {
            name: 'Transaction details',
            id: 'bank:transactions:read',
            description: 'This will allow us to access the transaction data of accounts',
            claims: [
              'Incoming and outgoing transactions',
              'Amounts',
              'Dates',
              'Descriptions of transactions',
              'Who you have sent money to and received money from (e.g. their name)',
            ],
            required: true,
            priority: 4,
          },
        ],
      },
    ];
  }
}

export default FakeUseCaseRepository;
