import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { ConsentResponse } from '@adatree/components';
import styled from 'styled-components';

export type ExistingDataStepProps = {
  consent: ConsentResponse;
};

const _Typography = styled(Typography)`
  ${({ theme }) => `
    margin: ${theme.spacing(0.5)} 0 ${theme.spacing(1)} 0;
  `}
`;

const _div = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
  `}
`;

const ExistingDataStep: React.FC<ExistingDataStepProps> = (props) => {
  const { consent } = props;

  return (
    <section className="margin-bt-xx">
      <Typography variant="h2" className="margin-bt-md">
        Here is the data you are currently sharing
      </Typography>

      {consent.useCase.scopes &&
        consent.useCase.scopes.map((scope, index) => {
          return (
            <Card className="margin-bt-lg" key={index}>
              <CardContent>
                <Typography variant="h3">{scope.name}</Typography>
                <_Typography variant="body1" className="margin-bt-sm">
                  {scope.description}
                </_Typography>

                {scope.claims && scope.claims.length > 0 && (
                  <>
                    <_div>
                      <ul className="list">
                        {scope.claims.map((item: string, i: number) => (
                          <li key={i}>{item}</li>
                        ))}
                      </ul>
                    </_div>
                  </>
                )}
              </CardContent>
            </Card>
          );
        })}
    </section>
  );
};

export default ExistingDataStep;
