import FakeDataHolderRepository from './fake-data-holder-repository';
import BackendDataHolderRepository from './backend-data-holder-repository';
import DataHolderRepository from '../../types/data-holder-repository';
import { logger, SETTINGS } from '@adatree/components';

let repository: DataHolderRepository = new BackendDataHolderRepository();
if (SETTINGS.simulateBackend) {
  logger.info('========== Simulating data holders calls ==========');
  repository = new FakeDataHolderRepository();
}

export default repository;
