import { getAccessToken } from '../../app/authentication/authentication';
import UseCaseRepository from '../../types/use-case-repository';
import { SETTINGS, ConsentConfig, ConsentConfigParams, UseCaseApiFactory, UseCaseResponse } from '@adatree/components';

class BackendUseCaseRepository implements UseCaseRepository {
  urlBase: string;
  configuration: ConsentConfig;

  constructor() {
    this.urlBase = `${SETTINGS.backendProtocol}${SETTINGS.backendDomain}/consent-mgmt`;
    const configurationParameters: ConsentConfigParams = {
      basePath: this.urlBase,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    this.configuration = new ConsentConfig(configurationParameters);
  }

  async findAll(): Promise<UseCaseResponse[]> {
    this.configuration.accessToken = await getAccessToken();
    const response = await UseCaseApiFactory(this.configuration, this.urlBase).findAllUseCases();

    return Promise.resolve(
      response.data.sort((a, b) => {
        if (a.priority && b.priority) {
          return a.priority - b.priority;
        } else {
          return 0;
        }
      })
    );
  }
}

export default BackendUseCaseRepository;
