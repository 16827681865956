import React from 'react';
import {
  Accordion,
  Dialog,
  logger,
  Formatter,
  ConsentResponse,
  UpdateConsent,
  Status,
  AccessFrequency,
  ScopeResponse,
  DataHolderHeader,
} from '@adatree/components';
import styled from 'styled-components';
import {
  Typography,
  Button,
  makeStyles,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ConsentRepository from '../../../types/consent-repository';
import { useHistory } from 'react-router-dom';

const _Button = styled((props) => <Button {...props} />)`
  width: fit-content;
  height: fit-content;
`;
4
const useStylesBackdrop = makeStyles({
  backdrop: {
    zIndex: 1400,
    color: '#fff',
  },
});

export type ConsentDetailOrganismProps = {
  consent: ConsentResponse;
  consentRepository: ConsentRepository;
};

// TODO: It needs a processing sign for when it's making requests to the backend
export const ConsentDetailOrganism: React.FC<ConsentDetailOrganismProps> = (props: ConsentDetailOrganismProps) => {
  const { consentRepository } = props;
  const [consent] = React.useState<ConsentResponse>(props.consent);
  const [updateConsent, setUpdateConsent] = React.useState<UpdateConsent>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState<boolean>(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const consentId: string = consent.consentId ? consent.consentId : '';
  const history = useHistory();
  const backdropClass = useStylesBackdrop();

  const handleRevokeClick = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const handleCloseInfoDialog = () => {
    history.push('/');
  };

  const handleRevoke = () => {
    setLoading(true);
    consentRepository
      .revoke(consentId)
      .then((revokedConsent) => {
        setOpenConfirmationDialog(false);
        setOpenInfoDialog(true);
        setUpdateConsent(Object.assign({}, { ...revokedConsent }));
        setLoading(false);
      })
      .catch((error) => {
        logger.error('Error handling Revoke', error, updateConsent);
        setLoading(false);
      });
  };

  if (consent) {
    const scopes = (
      <ul className="list">
        {consent.useCase &&
          consent.useCase.scopes &&
          consent.useCase.scopes.map((scope: ScopeResponse) => {
            return (
              <li key={scope.id}>
                <Typography>{scope.name}</Typography>
              </li>
            );
          })}
      </ul>
    );

    const purpose = consent.useCase ? <Typography>{consent.useCase.name}</Typography> : <></>;
    const accessFrequencyText = consent.useCase ? consent.useCase.accessFrequency : '';

    let sharingEndDate: Date | string = 'After first use';
    if (consent.useCase && consent.useCase.accessFrequency === AccessFrequency.ONGOING) {
      sharingEndDate = Formatter.formatDate(consent.sharingEndDate);
    }

    let firstDataCollection = 'Not yet';
    if (consent.firstDataCollection) {
      firstDataCollection = Formatter.formatDate(consent.firstDataCollection);
    }

    return (
      <>
        {loading && (
          <Backdrop className={backdropClass.backdrop} open={true}>
            <CircularProgress />
          </Backdrop>
        )}

        <section className="margin-bt-xx">
          <DataHolderHeader
            created={consent.created}
            name={consent.dataHolderName}
            onClick={handleRevokeClick}
            consentId={consent.consentId}
            revoked={consent.revoked}
            status={consent.status ? consent.status : Status.REQUESTED}
          />
        </section>

        <section className="margin-bt-xl">
          <Typography variant="h2" className="margin-bt-md">
            Data we are currently receiving
          </Typography>
          <Accordion title="Types of data received" content={scopes} />
          <Accordion title="Purpose of receiving data" content={purpose} />
        </section>

        <section className="margin-bt-xl">
          <Typography variant="h2" className="margin-bt-md">
            Key dates
          </Typography>
          <Card>
            <CardContent>
              <Typography variant="h4" className="margin-bt-xs" variantMapping={{ h4: 'h3' }}>
                When you gave consent
              </Typography>
              <Typography className="margin-bt-md">{Formatter.formatDate(consent.created)}</Typography>
              <Typography variant="h4" className="margin-bt-xs" variantMapping={{ h4: 'h3' }}>
                When we first collected your data
              </Typography>
              <Typography className="margin-bt-md">{firstDataCollection}</Typography>
              <Typography variant="h4" className="margin-bt-xs" variantMapping={{ h4: 'h3' }}>
                When your consent will expire
              </Typography>
              <Typography className="margin-bt-md">{sharingEndDate}</Typography>
              <Typography variant="h4" className="margin-bt-xs" variantMapping={{ h4: 'h3' }}>
                Data access frequency
              </Typography>
              <Typography>
                {accessFrequencyText.charAt(0).toUpperCase() + accessFrequencyText.slice(1).toLowerCase()}
              </Typography>
            </CardContent>
          </Card>
        </section>

        <_Button 
          component={RouterLink} 
          to="/"
          variant="contained"
          color="secondary">
          Back
        </_Button>

        <Dialog
          open={openConfirmationDialog}
          title={'Are you sure?'}
          contentText={`Revoking access will ensure that we are no longer able to receive your
            data from ${consent.dataHolderName}. Any revocation will take
            impact immediately. If you revoke consent, our service to you may be
            impacted. If you wish to change your settings about data that is
            deleted or de-identified, complete this before revoking, as it cannot
            be changed afterwards.`}
          closeButtonText={'Cancel'}
          handleClose={handleCloseConfirmationDialog}
          actionButtonText={'Confirm Revocation'}
          handleAction={handleRevoke}
        />

        <Dialog
          open={openInfoDialog}
          title={'Data access revoked'}
          contentText={`Revoking your consent to send us data from
          ${consent.dataHolderName} is now complete. We will no longer
          receive your ${consent.dataHolderName} data and will proceed
          with your post consent data usage instructions. A receipt of your
          revocation has been sent to the e-mail address associated with your`}
          closeButtonText={'Close'}
          handleClose={handleCloseInfoDialog}
        />
      </>
    );
  }
};
