import React from 'react';
import { Typography, Link } from '@material-ui/core';
import styled from 'styled-components';

const _Typography = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.highlight.light};
  `}
`;

const _Link = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.highlight.light};
  `}
`;
export type CopyrightProps = {
  companyName: string;
  companyWebSite: string;
};

export const Copyright: React.FC<CopyrightProps> = (props) => {
  const { companyName, companyWebSite } = props;

  return (
    <_Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <_Link color="inherit" href={companyWebSite}>
        {companyName}
      </_Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </_Typography>
  );
};
