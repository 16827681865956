import { UserManager } from 'oidc-react';

/* Simple state system fot eh app, can alway upgrade to Redux in the future if require */

declare global {
  interface Window {
    adt: State;
  }
}

export type State = {
  authentication: UserManager | null;
};

export enum StateType {
  Authentication = 'authentication',
}

export const initState = (): State => {
  window.adt = {
    authentication: null,
  };
  return window.adt;
};

export const getState = (): State => {
  return window.adt;
};

export const setState = (key: StateType, value: unknown): void => {
  if (key === 'authentication') {
    window.adt.authentication = value as UserManager;
  }
};
