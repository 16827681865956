import React, { ReactNode } from 'react';
import { Paper } from '@material-ui/core';
import { AppHeader, SETTINGS } from '@adatree/components';
import { Copyright } from '../../copyright';
import { useAuth } from 'oidc-react';
import { isAuthenticated } from '../../../app/authentication/authentication';
import styled from 'styled-components';

const _header = styled.div`
  ${({ theme }) => `
    background: ${theme.palette.background.header};
  `}
`;

const _div = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    background: ${theme.palette.background.main};
    margin-bottom: ${theme.spacing(4)};
  `}
`;

const _main = styled.main`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    ${theme.breakpoints.up('md')} {
      width: 96rem;
      margin: 0 auto;
    }
  `}
`;

const _Paper = styled(Paper)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    background-color: ${theme.palette.background.paper};
  `}
`;

interface Props {
  children: ReactNode;
}

export const PageWrapper: React.FC<Props> = ({ children }: Props) => {
  const auth = useAuth();
  const handleLogout = () => {
    if (auth) {
      auth.userManager.signoutRedirect();
    }
  };

  return (
    <_div>
      <_header>
         <AppHeader companyLogoUrl={SETTINGS.companyLogo} handleLogout={handleLogout} showLogout={isAuthenticated()} />
      </_header>
      <_main>
        <_Paper>{children}</_Paper>
      </_main>
      <Copyright companyName={SETTINGS.companyName} companyWebSite={SETTINGS.companyWebSite} />
    </_div>
  );
};
