import React from 'react';
import styled from 'styled-components';
import { Typography, List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@material-ui/core';
import { Formatter, CreateConsent, AccessFrequency, UseCaseResponse, PostUsageAction } from '@adatree/components';
import Bank from 'mdi-material-ui/Bank';
import Calendar from 'mdi-material-ui/Calendar';
import CheckboxMultipleMarked from 'mdi-material-ui/CheckboxMultipleMarked';
import Incognito from 'mdi-material-ui/Incognito';
import DeleteCircle from 'mdi-material-ui/Delete';
import CloudUpload from 'mdi-material-ui/CloudUpload';

const _section = styled.section`
  ${({ theme }) => `
    display: grid;
    grid-gap: ${theme.spacing(2)};
  `}
`;

const _SvgIcon = styled(SvgIcon)`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
  `}
`;

export type ReviewStepProps = {
  consent: CreateConsent;
  useCase: UseCaseResponse;
};

const ReviewStep: React.FC<ReviewStepProps> = (props: ReviewStepProps) => {
  const { consent, useCase } = props;
  const postActionText = consent.postUsageAction === PostUsageAction.DEIDENTIFICATION ? 'de-identifed' : 'deleted';
  const postActionIcon =
    consent.postUsageAction === PostUsageAction.DEIDENTIFICATION ? <Incognito /> : <DeleteCircle />;
  const frequencyText = useCase.accessFrequency === AccessFrequency.ONGOING ? 'ongoing' : 'once-off';
  let sharindEndDate: Date | string = 'after first use';
  if (useCase.accessFrequency === AccessFrequency.ONGOING) {
    sharindEndDate = Formatter.formatDate(consent.sharingEndDate);
  }
  return (
    <_section className="margin-bt-xx">
      <Typography variant="h2">Summary</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <_SvgIcon>
              <Bank />
            </_SvgIcon>
          </ListItemIcon>
          <ListItemText primary={`Your bank is ${consent.dataHolderName}`} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <_SvgIcon>
              <CloudUpload />
            </_SvgIcon>
          </ListItemIcon>
          <ListItemText primary={`Data sharing will be ${frequencyText}`} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <_SvgIcon>
              <Calendar />
            </_SvgIcon>
          </ListItemIcon>
          <ListItemText primary={`Sharing ends ${sharindEndDate}`} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <_SvgIcon>{postActionIcon}</_SvgIcon>
          </ListItemIcon>
          <ListItemText primary={`The data you share will be ${postActionText} when we're finished`} />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <_SvgIcon>
              <CheckboxMultipleMarked />
            </_SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary="You are sharing:"
            secondary={
              <ul className="list">
                {useCase.scopes &&
                  useCase.scopes.map((scope) => (
                    <li key={scope.id}>
                      <Typography>{scope.name}</Typography>
                    </li>
                  ))}
              </ul>
            }
          />
        </ListItem>
      </List>
      <Typography variant="body2">
        Consent may be withdrawn at any time for us to receive your data, either through our consent dashboard, from{' '}
        {consent.dataHolderName}&apos;s consent dashboard, or in writing to either party. If you withdraw consent, the
        services we offer may cease to provide you with benefits.
      </Typography>
      <Typography>
        When you consent you will be taken to {consent.dataHolderName} to confirm you want to share your bank
        information with us.
      </Typography>
    </_section>
  );
};

export default ReviewStep;
