// tslint:disable
/**
 * Consent Dashboard Infosec API
 * Consent Dashboard Infosec REST API for ADRs. This allows ADRs to perform CDR consumer authorization flow with Data Holders.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Authorization
 */
export interface Authorization {
    /**
     * Authorization code returned from the data holder that will be used as part of token request validation.
     * @type {string}
     * @memberof Authorization
     */
    code?: string;
    /**
     * The state is a parameter returned by the data holder that is used to prevent CSRF attacks. Must be unique per authorization request.
     * @type {string}
     * @memberof Authorization
     */
    state?: string;
    /**
     * ID Token value associated with the authenticated session that is returned from the data holder.
     * @type {string}
     * @memberof Authorization
     */
    id_token?: string;
}
/**
 * 
 * @export
 * @interface TokenCreatedResponse
 */
export interface TokenCreatedResponse {
    /**
     * Identifier of the consent which has now been activated by the completion of the Authorization flow.
     * @type {string}
     * @memberof TokenCreatedResponse
     */
    activeConsentId?: string;
}

/**
 * AuthorizationApi - axios parameter creator
 * @export
 */
export const AuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dataHolderBrandId The ideintifier of the data holder you wish the consumer to authenitcate with.
         * @param {string} [nonce] (deprecated) A nonce is generated by the consent user-agent (browser) and is sent in the authentication request, and is then also included in the ID Token response from the Data Holder. This allows us to correlate the ID Token response with the initial authentication request.
         * @param {string} [state] A state is generated by the consent user-agent (browser) and is used to bind the redirection response from a Data Holder with a user session as per https://openid.net/specs/openid-financial-api-part-1-1_0-final.html#public-client. It is sent in the authentication request, and is then also included redirect from the ADH to the ADR. This allows the ADR client application prevent CSRF attacks. Must be unique per authorization request.
         * @param {string} [cdrConsentId] (deprecated - use consentId) The identifier of the consent record to be associated with this authentication request.
         * @param {string} [consentId] The identifier of the consent record to be associated with this authentication request.
         * @param {string} [cdrArrangementId] The identifier of an existing CDR Arrangement that you wish to extend.
         * @param {string} [softwareProductId] The identifier of the software product registered at the ACCC registry to be associated with this authentication request. If omitted the configured default software product ID is used.
         * @param {string} [specificRedirectUri] (deprecated - use redirectUri) The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
         * @param {string} [redirectUri] The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorization: async (dataHolderBrandId: string, nonce?: string, state?: string, cdrConsentId?: string, consentId?: string, cdrArrangementId?: string, softwareProductId?: string, specificRedirectUri?: string, redirectUri?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataHolderBrandId' is not null or undefined
            if (dataHolderBrandId === null || dataHolderBrandId === undefined) {
                throw new RequiredError('dataHolderBrandId','Required parameter dataHolderBrandId was null or undefined when calling getAuthorization.');
            }
            const localVarPath = `/authorization`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (nonce !== undefined) {
                localVarQueryParameter['nonce'] = nonce;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (dataHolderBrandId !== undefined) {
                localVarQueryParameter['dataHolderBrandId'] = dataHolderBrandId;
            }

            if (cdrConsentId !== undefined) {
                localVarQueryParameter['cdrConsentId'] = cdrConsentId;
            }

            if (consentId !== undefined) {
                localVarQueryParameter['consentId'] = consentId;
            }

            if (cdrArrangementId !== undefined) {
                localVarQueryParameter['cdrArrangementId'] = cdrArrangementId;
            }

            if (softwareProductId !== undefined) {
                localVarQueryParameter['softwareProductId'] = softwareProductId;
            }

            if (specificRedirectUri !== undefined) {
                localVarQueryParameter['specificRedirectUri'] = specificRedirectUri;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = redirectUri;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dataHolderBrandId The ideintifier of the data holder you wish the consumer to authenitcate with.
         * @param {string} [nonce] (deprecated) A nonce is generated by the consent user-agent (browser) and is sent in the authentication request, and is then also included in the ID Token response from the Data Holder. This allows us to correlate the ID Token response with the initial authentication request.
         * @param {string} [state] A state is generated by the consent user-agent (browser) and is used to bind the redirection response from a Data Holder with a user session as per https://openid.net/specs/openid-financial-api-part-1-1_0-final.html#public-client. It is sent in the authentication request, and is then also included redirect from the ADH to the ADR. This allows the ADR client application prevent CSRF attacks. Must be unique per authorization request.
         * @param {string} [cdrConsentId] (deprecated - use consentId) The identifier of the consent record to be associated with this authentication request.
         * @param {string} [consentId] The identifier of the consent record to be associated with this authentication request.
         * @param {string} [cdrArrangementId] The identifier of an existing CDR Arrangement that you wish to extend.
         * @param {string} [softwareProductId] The identifier of the software product registered at the ACCC registry to be associated with this authentication request. If omitted the configured default software product ID is used.
         * @param {string} [specificRedirectUri] (deprecated - use redirectUri) The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
         * @param {string} [redirectUri] The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorization(dataHolderBrandId: string, nonce?: string, state?: string, cdrConsentId?: string, consentId?: string, cdrArrangementId?: string, softwareProductId?: string, specificRedirectUri?: string, redirectUri?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AuthorizationApiAxiosParamCreator(configuration).getAuthorization(dataHolderBrandId, nonce, state, cdrConsentId, consentId, cdrArrangementId, softwareProductId, specificRedirectUri, redirectUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} dataHolderBrandId The ideintifier of the data holder you wish the consumer to authenitcate with.
         * @param {string} [nonce] (deprecated) A nonce is generated by the consent user-agent (browser) and is sent in the authentication request, and is then also included in the ID Token response from the Data Holder. This allows us to correlate the ID Token response with the initial authentication request.
         * @param {string} [state] A state is generated by the consent user-agent (browser) and is used to bind the redirection response from a Data Holder with a user session as per https://openid.net/specs/openid-financial-api-part-1-1_0-final.html#public-client. It is sent in the authentication request, and is then also included redirect from the ADH to the ADR. This allows the ADR client application prevent CSRF attacks. Must be unique per authorization request.
         * @param {string} [cdrConsentId] (deprecated - use consentId) The identifier of the consent record to be associated with this authentication request.
         * @param {string} [consentId] The identifier of the consent record to be associated with this authentication request.
         * @param {string} [cdrArrangementId] The identifier of an existing CDR Arrangement that you wish to extend.
         * @param {string} [softwareProductId] The identifier of the software product registered at the ACCC registry to be associated with this authentication request. If omitted the configured default software product ID is used.
         * @param {string} [specificRedirectUri] (deprecated - use redirectUri) The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
         * @param {string} [redirectUri] The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorization(dataHolderBrandId: string, nonce?: string, state?: string, cdrConsentId?: string, consentId?: string, cdrArrangementId?: string, softwareProductId?: string, specificRedirectUri?: string, redirectUri?: string, options?: any): AxiosPromise<string> {
            return AuthorizationApiFp(configuration).getAuthorization(dataHolderBrandId, nonce, state, cdrConsentId, consentId, cdrArrangementId, softwareProductId, specificRedirectUri, redirectUri, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {string} dataHolderBrandId The ideintifier of the data holder you wish the consumer to authenitcate with.
     * @param {string} [nonce] (deprecated) A nonce is generated by the consent user-agent (browser) and is sent in the authentication request, and is then also included in the ID Token response from the Data Holder. This allows us to correlate the ID Token response with the initial authentication request.
     * @param {string} [state] A state is generated by the consent user-agent (browser) and is used to bind the redirection response from a Data Holder with a user session as per https://openid.net/specs/openid-financial-api-part-1-1_0-final.html#public-client. It is sent in the authentication request, and is then also included redirect from the ADH to the ADR. This allows the ADR client application prevent CSRF attacks. Must be unique per authorization request.
     * @param {string} [cdrConsentId] (deprecated - use consentId) The identifier of the consent record to be associated with this authentication request.
     * @param {string} [consentId] The identifier of the consent record to be associated with this authentication request.
     * @param {string} [cdrArrangementId] The identifier of an existing CDR Arrangement that you wish to extend.
     * @param {string} [softwareProductId] The identifier of the software product registered at the ACCC registry to be associated with this authentication request. If omitted the configured default software product ID is used.
     * @param {string} [specificRedirectUri] (deprecated - use redirectUri) The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
     * @param {string} [redirectUri] The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public getAuthorization(dataHolderBrandId: string, nonce?: string, state?: string, cdrConsentId?: string, consentId?: string, cdrArrangementId?: string, softwareProductId?: string, specificRedirectUri?: string, redirectUri?: string, options?: any) {
        return AuthorizationApiFp(this.configuration).getAuthorization(dataHolderBrandId, nonce, state, cdrConsentId, consentId, cdrArrangementId, softwareProductId, specificRedirectUri, redirectUri, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TokensApi - axios parameter creator
 * @export
 */
export const TokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Authorization} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (authorization?: Authorization, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tokens`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof authorization !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(authorization !== undefined ? authorization : {}) : (authorization || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokensApi - functional programming interface
 * @export
 */
export const TokensApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Authorization} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(authorization?: Authorization, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenCreatedResponse>> {
            const localVarAxiosArgs = await TokensApiAxiosParamCreator(configuration).createToken(authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TokensApi - factory interface
 * @export
 */
export const TokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Authorization} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(authorization?: Authorization, options?: any): AxiosPromise<TokenCreatedResponse> {
            return TokensApiFp(configuration).createToken(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
export class TokensApi extends BaseAPI {
    /**
     * 
     * @param {Authorization} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public createToken(authorization?: Authorization, options?: any) {
        return TokensApiFp(this.configuration).createToken(authorization, options).then((request) => request(this.axios, this.basePath));
    }

}


