import React from 'react';
import { Button } from '@material-ui/core';

export type LogoutButtonProps = {
  handleClick: () => void;
};

export const LogoutButton: React.FC<LogoutButtonProps> = (props) => {
  const { handleClick } = props;

  return (
    <Button id="logout" variant="contained" color="primary" onClick={handleClick}>
      Logout
    </Button>
  );
};
