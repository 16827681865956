import DataHolderRepository from '../../types/data-holder-repository';
import { TestUtil, DataHolder } from '@adatree/components';

class FakeDataHolderRepository implements DataHolderRepository {
  async findAll(): Promise<DataHolder[]> {
    return TestUtil.getTestDataDataHolder();
  }
}

export default FakeDataHolderRepository;
