import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ConsentRepository from '../types/consent-repository';
import { ConsentResponse } from '@adatree/components';
import Skeleton from '@material-ui/lab/Skeleton';
import { ExtendConsentOrganism } from '../components/organism/extend-consent/extend-consent.organism';
import styled from 'styled-components';
import InfosecService from '../types/infosec-service';

const _div = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(3)} 0;
    padding: ${theme.spacing()};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(5)};
    }
  `}
`;

export type ExtendConsentTemplateParams = {
  consentId: string;
};
interface ExtendConsentTemplateProps extends RouteComponentProps<ExtendConsentTemplateParams> {
  consentRepository: ConsentRepository;
  infosecService: InfosecService;
}

export const ExtendConsentTemplate: React.FC<ExtendConsentTemplateProps> = (props: ExtendConsentTemplateProps) => {
  const { consentRepository, infosecService } = props;
  const consentId: string = props.match.params.consentId;
  const [consent, setConsent] = React.useState<ConsentResponse>();
  const [isloading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    consentRepository.findConsentById(consentId).then((foundConsent: ConsentResponse) => {
      setConsent(Object.assign({}, foundConsent));
      setIsLoading(false);
    });
  }, [consentId, consentRepository]);

  return (
    <>
      <_div>
        {isloading && (
          <>
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
          </>
        )}
        {!isloading && consent && (
          <ExtendConsentOrganism
            consent={consent}
            consentRepository={consentRepository}
            infosecService={infosecService}
          />
        )}
      </_div>
    </>
  );
};
