import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { SETTINGS } from '@adatree/components';

export const Footer: React.FC = () => {
  return (
    <Typography variant="caption">
      {'Please read our '}
      <Link href={SETTINGS.companyCdrPolicyUrl} target="_blank">
        Consumer Data Right Policy
      </Link>
      {` for more information on how ${SETTINGS.companyName} handles your data.`}
    </Typography>
  );
};
